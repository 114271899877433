import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import user from "@/store/modules/user";
import client from "@/store/modules/client";
import kanban from "@/store/modules/kanban";
import config from "@/store/modules/config";
import customer_request from "@/store/modules/customer_request";

export default new Vuex.Store({
    modules: {
        user,
        client,
        kanban,
        config,
        customer_request,
    },
    strict: process.env.NODE_ENV !== "production",
});
