<template>
    <div style="width: 100%">
        <a-select
            ref="refASelect"
            v-model="selected"
            style="width: 100%"
            :placeholder="placeholder"
            allowClear
            :disabled="disabled"
            :autoClearSearchValue="false"
            :showSearch="showSearch"
            @change="change"
            @search="search"
            @deselect="deselect"
            :filterOption="filter_option"
            @popupScroll="popupScroll"
            @dropdownVisibleChange="openSelect"
        >
        </a-select>
    </div>
</template>

<script>
import PaginationMixin from "@/Mixins/PaginationMixin";
import SelectList from "./SelectList"
import axios from "@/configs/axios";
import {EventBus} from "@/configs/eventBus";
let searchId;
export default {
    props: {
        value: Object|String,
        select_list_component:{
            type:Object,
            default: ()=>{
              return SelectList
            }
        },
        client_data_params:{
          type: Array,
          default: null,
        },
        repeat_request_event:{
          type: Array,
          default: null,
        },
        use_last_request_params_event:{
          type: Boolean,
          default: false,
        },
        clear_on_change:{
          type: Boolean,
          default: false,
        },
        placeholder: {
            type: String,
            default: ''
        },
        name_getters: {
            type: String,
            default: undefined,
        },
        name: {
            type: String,
            default: ''
        },
        disabled:{
            type:Boolean,
            default:false,
        },
        showSearch:{
            type:Boolean,
            default:true,
        },
        trackBy: { // Ключ индификатора
            type: String,
            default: 'uid'
        },
        clientData:{
            type: Object,
            default: null,
        },
        end_point: {
            type: String,
            default: ''
        },
        innerParams: {
            type: Object,
            default: () => {}
        },
        innerParamsFunction: {
            type: Function,
            default: () => {}
        },
        check_request_params: {
            type: Function,
            default: () => {}
        },
        filterOption: {
            type: Function,
        },
        router_field:{
            type:String,
            default: 'uid'
        },
        option:{
            type:Array,
            required:false,
        },
        use_router_replace:{
            type: Boolean,
            default: false,
        },
        mode:{
            type:String,
            default: 'default'
        },
        http_request:{
            type:String,
            default: 'get'
        },
        filter_field:{
            type:String,
            default: 'name'
        },
        use_last_request_params:{
            type: Boolean,
            default: false,
        },
        auto_get_selected:{
            type: Boolean,
            default: false,
        },
        type_show:{
            type: String,
            default: ''
        },
        updated_event_bus:{
          type: String,
          default: ''
        },

    },
    async created() {
      try {
        if (this.use_last_request_params_event){
          EventBus.$on('event_bus_'+this.name, (val) => {
            this.updated_event=true
            this.page = 0
            if (this.clear_on_change){
              this.deselect()
            }
          })
        }

      }catch (e) {
        console.log(e)
      }
        if (this.$route.query[this.name] && this.use_router_replace){
          this.selected = await this.$route.query[this.name]
        }
        await this.openSelect(true)
    },
    mixins:[
        PaginationMixin,
    ],
    computed: {

    },
    data() {
        return {
            loading: false,
            options: [],
            selected: this.value,
            last_request_params:null,
            once: false,
            updated_event: false,
        }
    },
    watch: {
        value(val) {
            this.selected = val
        }
    },
    methods: {

      function_to_get_list(params){
        let clone_selected = this.selected
        if(!Array.isArray(clone_selected) && clone_selected){
          clone_selected = [clone_selected]
        }
        let clone_client_data = {}
        if (this.client_data_params){
          this.client_data_params.forEach(el=>{
            clone_client_data[el] = this.clientData[el]
          })
        }
        return new Promise((resolve, reject) => {
          axios[this.http_request](this.end_point, {params:{...params, selected:clone_selected, ...clone_client_data}} )
              .then(async (responce) => {
                  responce = responce.data
                  if(this.options) {
                    this.options.push(...responce.results)
                  }else{
                    this.options = responce.results
                  }
                  return resolve(responce)
              })
              .catch((error) => {
                  console.log(error)
                  return reject(error)
              })
        })
      },
      clean_data_function(){
        this.options = []
      },
        async openSelect(open) {


        },
        async change(val){
            console.log('this.selected', this.selected)
            if (!this.selected || this.selected.length === 0){
                await this.search(null)
                this.options = []
            }
            this.$emit('input', {name:this.name, select:this.selected} );
          console.log('this.selected', this.selected)
          if (this.use_router_replace){
            await this.$router.replace({query:{...this.$route.query, [this.name]: this.selected}})
            console.log('EventBus', this.updated_event_bus)

          }
          if(this.repeat_request_event){
            this.repeat_request_event.forEach((event)=>{
              EventBus.$emit('event_bus_'+event)
            })
          }
        },
        deselect(value, option){
            this.selected = undefined
            this.$emit('input', {name:this.name, select:this.selected} );
          if (this.use_router_replace){
            this.$router.replace({query:{...this.$route.query, [this.name]: this.selected}})
          }
          if(this.repeat_request_event){
            this.repeat_request_event.forEach((event)=>{
              EventBus.$emit('event_bus_'+event)
            })
          }
        },
        popupScroll(val){
            let target = val.target
            if ((target.scrollTop + target.clientHeight+0.5) >= target.scrollHeight) {
              console.log('popupScroll')
              this.infiniteHandle(false)
            }
        },
        filter_option(value, option){

            if (this.filterOption){
                return this.filterOption(value, option, this.options)
            }
            let object = this.options.find(el=>el.uid===option.data.key)
            if (object){
                const expression = `return object.${ this.filter_field }.toLowerCase().indexOf(value.toLowerCase()) >= 0`;
                return Function("object", "value",expression)(object, value);
            }
            return false
        },
        async search(value){
            clearTimeout(searchId);
            searchId = setTimeout(async () => {
                this.other_params = {search: value}
                await this.clean_data_function()
                console.log('search')
                await this.infiniteHandle({update:true})
            }, 600);

        }
    }
}
</script>
