<script>
import {mapActions} from "vuex";

export default {
  name: "GetHistory",
  methods:{
    ...mapActions({
      GET_HISTORY_CHANGE:'user/GET_HISTORY_CHANGE',
    }),
    get_history_change(uid){
      return new Promise((resolve, reject) => {
        this.GET_HISTORY_CHANGE({uid:uid}).then((res)=>{
          resolve(res.history_change.sort((a, b) => new Date(a.action_date) - new Date(b.action_date)));
        })

      });
    }
  }
}
</script>
