<script>

export default {
    name: "PaginationMixin",
    props:{
        // action:String,
        use_pagination:Boolean,
        // function_to_get_list:Function,
        // terms_request:{
        //     type:Boolean,
        //     default:true,
        // },
        type_show:{
            type: String,
            default: ''
        }
    },
    data() {
        return {
            infiniteStatus:true,
            loading: false,
            page: 0,
            other_params:{}
        }
    },
    methods:{
        async infiniteHandle({update = false}) {
          console.log('update', update)
          console.log('this.page', this.page)
            if (this.use_pagination){

                // Если update = true запрашиваем список заного с новыми параметрами
                if(update) {
                    this.infiniteStatus = true
                    this.page = 0
                }
                // Запрашиваем список
                await this.getList()
            }
        },
        async getList() {
            if(!this.loading && this.infiniteStatus && this.function_to_get_list) {
                try {
                    this.loading = true
                  await this.$nextTick().then(() => {
                    // if(this.type_show !== 'ant-select'){
                    // }
                  })

                    await this.sleep(500)  // что то не успевало, так и не понял что, пока что временно усыпляю
                    this.page += 1
                    const res = await this.function_to_get_list({
                        ...this.$route.query,
                        ...this.other_params,
                        page: this.page,
                    })
                    await console.log('resresresresres', res);

                    // Если запросили последнюю страницу, меняем статус инфинити скролла
                    if(!res || !res.next) this.infiniteStatus = false
                } catch(e) {
                    console.log('error',e)
                } finally {
                    this.loading = false
                    // await this.$vs.loading.close('#infinite_loader > .con-vs-loading')
                }
            }
        },
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
    }
};
</script>

<style scoped>

</style>
