<template>
<div>
  <FormSelect :formItems="formItemsCreateRequest" ref="refFormCreateRequest" :cols="1" :show_label="true"/>
  <div class="flex justify-start py-2">
    <a-button
        type="primary"
        class="default-button mr-1"
        size="small"
        @click="handleSubmit($refs.refFormCreateRequest, 'create_request', 'organizations/customer/')">
      Сохранить
    </a-button>
  </div>
</div>
</template>

<script>
import FormSelect from "@/components/Forms/FormAddClient/FormSelect";
import ProfileSelectView from "@/components/AntSelect/ProfileSelectView";
import FormItemsUpdate from "@/pages/AddClient/RequestMixins/FormItemsUpdate";
export default {
  name: "AddRequest",
  components:{
    FormSelect,
  },
  data(){
    return{

    }
  },
  mixins:[
    FormItemsUpdate,
  ],
  methods:{
    handleSubmit(refForm, name_event_bus, end_point){
      this.$emit('handleSubmit', refForm, name_event_bus, end_point)
    }
  },
  mounted() {
    this.$refs.refFormCreateRequest.clientData = {
      ...this.$refs.refFormCreateRequest.clientData,
      client:this.$route.query.phone_number
    }
  }
}
</script>

<style scoped>

</style>
