<script>
import {EventBus} from "@/configs/eventBus";
export default {
  name: "WebSocketTest",
  data() {
    return {
      socket: null,
      message: "",
      serverMessage: "",
    };
  },
  mounted() {
    this.connectWebSocket();
  },
  methods: {
    connectWebSocket() {
      let base_url = `wss://${window.location.hostname}${window.location.port ? ':8001': ''}`
      if (window.location.hostname === 'localhost') {
        base_url = 'ws://localhost:8081';
      }
      const user = JSON.parse(localStorage.getItem('user'));
      this.socket = new WebSocket(`${ base_url }/ws/your_consumer/?token=${user.access}`);

      this.socket.onopen = () => {
        console.log("[open] Соединение установлено");
      };

      this.socket.onmessage = (event) => {
        console.log(`[message] Данные получены с сервера: ${event.data}`);
        this.serverMessage = event.data;
        console.log('event', event)
        console.log('event.data', event.data)

        let data = JSON.parse(event.data);
        console.log('data', data)
        EventBus.$emit('open_modal_client_and_request', true, data.message, true);
      };

      this.socket.onclose = (event) => {
        if (event.wasClean) {
          console.log(`[close] Соединение закрыто чисто, код=${event.code} причина=${event.reason}`);
        } else {
          console.log('[close] Соединение прервано');
        }
      };

      this.socket.onerror = (error) => {
        console.log(`[error] ${error.message}`);
      };
    },
  },
  beforeDestroy() {
    if (this.socket) {
      this.socket.close();
    }
  }
};
</script>

<style scoped>
/* Добавьте стили по желанию */
</style>
