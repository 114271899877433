<template>
  <div>
    <div>
      <div class="w-full mb-4">
        <a-input v-model="address['full_address']" disabled style="width: 100%;" :name="name_input"/>
      </div>
      <div class="w-full">
        <GmapAutocomplete
            :placeholder="$t('search_address')"
            class="ant-input"
            style="width: 100%;"
            @place_changed="findPlace"
        />
      </div>
    </div>
    <GmapMap
        :center="center"
        :zoom="12"
        style="width: 100%; height: 400px;"
        @click="placeMarker"
        @dblclick="placeMarker"
    >
      <GmapMarker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          @click="center = m.position"
      />
    </GmapMap>
  </div>
</template>

<script>
export default {
  name: 'GoogleMap',
  props: {
    value: {
      type: Object,
      default: {
        full_address:'sadads'
      },
    },
    name_input: {
      type: String,
    },
  },
  data() {
    return {
      center: { lat: 45.508, lng: -73.587 },
      markers: [],
      address: this.value,
    };
  },
  mounted() {
    this.geolocate();
  },
  computed: {
    fullAddress() {
      if (this.address) {
        return this.address.full_address;
      }
    },
  },
  methods: {
    async findPlace(place) {
      await this.set_place(place.geometry.location.lat(), place.geometry.location.lng());
    },
    geolocate() {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
    async set_place(lat, lng){
      const newMarker = {
        lat: lat,
        lng: lng,
      };
      this.center = { lat, lng };
      this.address = await this.getAddress(lat, lng);
      this.markers = [{ position: newMarker }];
      await this.$emit('change', this.address);
    },
    async placeMarker(event) {
      await this.set_place(event.latLng.lat(), event.latLng.lng());
    },
    async getAddress(lat, lng) {
      const geocoder = new google.maps.Geocoder();
      const latlng = { lat, lng };
      return new Promise((resolve, reject) => {
        geocoder.geocode({ location: latlng }, (results, status) => {
          if (status === 'OK') {
            if (results[0]) {
              let country = '',
                  city = '',
                  street = '',
                  house = '';
              for (let i = 0; i < results[0].address_components.length; i++) {
                let addr = results[0].address_components[i];
                if (addr.types.includes('country')) country = addr.long_name;
                else if (addr.types.includes('locality')) city = addr.long_name;
                else if (addr.types.includes('route')) street = addr.long_name;
                else if (addr.types.includes('street_number')) house = addr.long_name;
              }
              resolve({
                city: city,
                country: country,
                street: street,
                house: house,
                longitude: lng,
                latitude: lat,
                full_address: `${country}, ${city}, ${street}, ${house}`,
              });
            } else {
              console.log('No results found');
              resolve(null);
            }
          } else {
            console.log(`Geocoder failed due to: ${status}`);
            resolve(null);
          }
        });
      });
    },
  },
};
</script>

<style scoped>
/* Add any styles if necessary */
</style>
