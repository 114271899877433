<script>
export default {
  name: "MapMixin",
  data() {
    return {
      center: { lat: 45.508, lng: -73.587 },
      markers: [],
    };
  },
  mounted() {
    this.geolocate();
  },
  methods: {
    // async findPlace(place, name) {
    //   await this.set_place(place.geometry.location.lat(), place.geometry.location.lng(), name);
    // },
    geolocate() {
      navigator.geolocation.getCurrentPosition(position => {
        console.log('this.center', this.center)
        if (this.center.lat === 45.508 && this.center.lng === -73.587) {
          this.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

        }
      });
    },
    async set_place(lat, lng, name, result=null){
      const newMarker = {
        lat: lat,
        lng: lng,
      };
      this.center = { lat, lng };
      let address = await this.getAddress(result);
      this.markers = [{ lat: lat, lng:lng, name:name,}];
      await this.change_map(name, address)
      // await this.$emit('change', this.address);
    },
    async placeMarker(event, item) {
      console.log('event', event)
      console.log('item', item)
      if (item.read_only !== true){
        await this.set_place(event.raw.lat, event.raw.lon, item.model, event);
      }
    },
    async onMapClick (value, item) {
      if (item.read_only !== true){
        console.log(value)
        this.position = value.latlng
        this.searchVisible = false
        let address = 'Не удалось определить адрес'
        let name = ''
        this.$emit('inputLoading', true)
        try {
          const { lat, lng } = this.position
          const result = await fetch(
              `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}&accept-language=ru`
          )
          if (result.status === 200) {
            const body = await result.json()
            address = body.display_name
            name = `${address.split(', ', 2)[1]}, ${address.split(', ', 2)[0]}`
            console.log('body', body)
            let custom_address = {
              city: body.address.city,
              country: body.address.country,
              street: body.address.road,
              house: body.address.house_number,
              longitude: this.position.lng,
              latitude: this.position.lat,
              full_address: address,
            }
            await this.change_map(item.model, custom_address)
            this.markers = [{ lat: this.position.lat, lng:this.position.lng, name:name,}];
          }
        } catch (e) {
          console.error('Reverse Geocode Error->', e)
        } finally {
          this.$emit('inputLoading', false)
        }
        console.log('address', address)
        console.log('name', name)
      }
      // this.deliveryPoint.lat = this.position.lat
      // this.deliveryPoint.lon = this.position.lng
      // this.deliveryPoint.address = address
      // this.deliveryPoint.name = name
      // this.$emit('markedPoint', this.deliveryPoint)
    },
    getAddress(result) {
      // this.searchVisible = true
      // this.$refs['l-map'].mapObject.setView({lat: result.y, lng: result.x}, 17)
      // if(result.bounds)
      //   this.$refs['l-map'].mapObject.fitBounds(result.bounds)
      // this.searchAdress = {
      //   lat: result.raw.lat,
      //   lng: result.raw.lon
      // }
      // this.shortAddress = result.label.slice(0, 25)+'...'
      // this.deliveryPoint.lat = result.raw.lat
      // this.deliveryPoint.lon = result.raw.lon
      // this.deliveryPoint.address = result.label
      // this.deliveryPoint.name = `${result.label.split(', ', 2)[0]}, ${result.label.split(', ', 2)[1]}`
      // this.$emit('markedPoint', this.deliveryPoint)
      console.log('result', result)
      return new Promise((resolve, reject) => {
          resolve({
            city: result.label,
            country: result.label,
            street: result.label,
            house: result.label,
            longitude: result.raw.lon,
            latitude: result.raw.lat,
            full_address: result.label,
          });
      });
    },
    // async getAddress(lat, lng) {
    //   const geocoder = new google.maps.Geocoder();
    //   const latlng = { lat, lng };
    //   return new Promise((resolve, reject) => {
    //     geocoder.geocode({ location: latlng }, (results, status) => {
    //       if (status === 'OK') {
    //         if (results[0]) {
    //           let country = '',
    //               city = '',
    //               street = '',
    //               house = '';
    //           for (let i = 0; i < results[0].address_components.length; i++) {
    //             let addr = results[0].address_components[i];
    //             if (addr.types.includes('country')) country = addr.long_name;
    //             else if (addr.types.includes('locality')) city = addr.long_name;
    //             else if (addr.types.includes('route')) street = addr.long_name;
    //             else if (addr.types.includes('street_number')) house = addr.long_name;
    //           }
    //           resolve({
    //             city: city,
    //             country: country,
    //             street: street,
    //             house: house,
    //             longitude: lng,
    //             latitude: lat,
    //             full_address: `${country}, ${city}, ${street}, ${house}`,
    //           });
    //         } else {
    //           console.log('No results found');
    //           resolve(null);
    //         }
    //       } else {
    //         console.log(`Geocoder failed due to: ${status}`);
    //         resolve(null);
    //       }
    //     });
    //   });
    // },
  },
}
</script>

<style scoped>

</style>
