<template>
  <div>
    <custom-drawer :visible="visible" ref="refCustomDrawer" v-if="data" :key_delete_router="['phone_number', 'stab']" @close="close">
      <template slot="title">
        {{data.data.full_name}}
      </template>
      <template slot="tabs">
        <custom-tabs :tabs="tabs" :visible="visible"/>
      </template>
      <template slot="content">
        <div class="flex w-full" style="margin-top: 100px;">
          <div class="w-full" >
            <div class="w-full" v-if="$route.query.stab === 'requests'">
              <ClientInfo :data="data.data"/>
<!--              <card>-->
<!--                <div>-->
<!--                  <AgGridTable-->
<!--                      :query_page_name="'customer_request_page'"-->
<!--                      :query_name="'search_customer'"-->
<!--                      ref="refAgGridCreateRequest"-->
<!--                      :column_defs="column_defs_create_request"-->
<!--                      :end_point="'organizations/customer/'"-->
<!--                      :name_event_bus="'create_request'"-->
<!--                      @open_drawer="open_drawer_request"/>-->
<!--                </div>-->
<!--              </card>-->
            </div>
          </div>
        </div>
      </template>
      <template slot="content_bottom">
        <a-dropdown >
          <color-icon/>
          <a-menu slot="overlay">
            <a-menu-item>
              <a @click="edit">
                {{$t('edit')}}
              </a>
            </a-menu-item>
            <a-menu-item @click="clone_delete_client">
              <a>
                {{$t('delete')}}
              </a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </template>
    </custom-drawer>
  </div>
</template>

<script>
import FormAndAgGrid from "@/components/Forms/FormAddClient/FormAndAgGrid";
import Actions from "@/components/Forms/Actions";
import validate_iin from "@/Mixins/validate/validate_iin";
import validate_email from "@/Mixins/validate/validate_email";
import Card from "@/crm_components/card/Card";
import AgGridTable from "@/components/Forms/FormAddClient/AgGridTable";
import CardV2 from "@/crm_components/card_v2/CardV2";
import BigIcon from "@/crm_components/icons/BigIcon";
import FormSelect from "@/components/Forms/FormAddClient/FormSelect";
import formSaveMixin from "@/crm_components/form_save/FormSaveMixin";
import CustomDrawer from "@/crm_components/CustomDrawer/CustomDrawer";
import CardDrawer from "@/crm_components/CustomDrawer/CardDrawer";
import InfoDrawer from "@/crm_components/CustomDrawer/InfoDrawer";
import FormattedDateMixin from "@/crm_components/mixins/FormattedDateMixin";
import ClientInfo from "@/pages/AddClient/Info/ClientInfo";
import ColorIcon from "@/crm_components/icons/ColorIcon";
import CustomTabs from "@/crm_components/custom_tabs/CustomTabs";
import SelectView from "@/pages/CustomerRequest/SelectView";
import AddRequest from "@/pages/AddClient/AddRequest";
import RequestShowDrawer from "@/pages/AddClient/Request/RequestShowDrawer";
import ColumnDefsCreateRequest from "@/pages/AddClient/RequestMixins/ColumnDefsCreateRequest";
import DeleteMixin from "@/pages/AddClient/Client/DeleteMixin/DeleteMixin";
import RoleMixin from "@/Mixins/RoleMixin";
export default {
  name: "Client",
  components: {
    RequestShowDrawer,
    AddRequest,
    CustomTabs,
    ColorIcon,
    InfoDrawer,
    CardDrawer,
    CustomDrawer,
    BigIcon,
    CardV2,
    Card,
    FormAndAgGrid,
    Actions,
    AgGridTable,
    FormSelect,
    ClientInfo
  },
  data(){
    return{
      tabs:[
        {name:this.$t('requests'), key:'requests'},
        {name:this.$t('call_history'), key:'call_history'},
      ],
    }
  },
  mixins:[
    ColumnDefsCreateRequest,
    formSaveMixin,
    DeleteMixin,
    RoleMixin,
  ],
  props:{
    visible:{
      type:Boolean,
      default: false
    },
    data:{
      type: Object,
      default: {}
    }
  },
  methods:{
    edit(){
      this.$emit('edit')
    },
    async open_drawer_request(data, edit = false){
      this.$emit('open_drawer_request', data, edit)
    },
    clone_delete_client(){
      this.delete_method('clients_event_bus', 'organizations/profiles_client/', this.data.data)

    },
    close(){
      this.$emit('close')
    },
  }
}
</script>

<style scoped>

</style>
