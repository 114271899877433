<template>
<div>
    {{item.full_name}}
</div>
</template>

<script>
export default {
    name: "SelectList",
    props: {
        item: {
            type: Object,
            required: true
        }
    },
}
</script>

<style scoped>

</style>
