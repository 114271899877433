<script>
import {EventBus} from "@/configs/eventBus";

export default {
  name: "OpenDrawerRequestMixin",
  methods:{
    async open_drawer_request(data, edit = false){
      console.log('data', data)
      EventBus.$emit('event_request_show_drawer', data.data, edit);

      // await this.$refs.refCustomDrawer.onClose()
      // try {
      //   console.log('this.$route.query', this.$route.query)
      //   await this.$router.push({query:{...this.$route.query, customer_request: data.data.uid,  parent:data.data.uid, }})
      //
      // }catch (e) {
      // }
      //
      // if (this.$refs.refClientShowDrawer){
      //   this.$refs.refClientShowDrawer.visible = await false
      //   this.$refs.refClientShowDrawer.visible_edit = await false
      // }
      // await this.$refs.refRequestShowDrawer.mounted_func()
      //
      // if (edit){
      //   await this.$refs.refRequestShowDrawer.edit()
      // }
      //
      //
      //
      // await this.$nextTick(); // Подождем, пока DOM обновится
    },
  }
}
</script>

