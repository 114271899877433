<template>
  <div class="">
    <component :is="component_type" :trigger="['click']" >
      <div>
        <div v-if="is_watch">
          <a-icon type="menu" class="menu-color-icon text-center cursor-pointer"/>
        </div>
        <div v-else>
          <div v-if="take_over" @click="handleTakeOver">
            {{$t('take_over')}}
          </div>
          <div v-else-if="text_chenge_status" @click="change_status(text_chenge_status)">
            {{text_chenge_status.name}}
          </div>
          <div v-else>
            <a-icon type="menu" class="menu-color-icon text-center cursor-pointer"/>
          </div>
        </div>

      </div>
      <a-menu slot="overlay">
        <a-menu-item v-if="is_watch">
          <a @click="watch_request">
            {{$t('watch')}}
          </a>
        </a-menu-item>
        <a-menu-item @click="handleEdit" v-if="GET_PROFILE_INFO.can_edit_requests">
          <a>
            {{$t('edit')}}
          </a>
        </a-menu-item>
        <a-menu-item @click="handleDelete" v-if="GET_PROFILE_INFO.can_edit_requests">
          <a>
            {{$t('delete')}}
          </a>
        </a-menu-item>
        <a-menu-item @click="change_status(ele)" v-if="available_statuses" v-for="(ele, index) in available_statuses" :key="index">
          <a>
            <a-badge :color="ele.color" :text="ele.name" />
          </a>
        </a-menu-item>
      </a-menu>
    </component>
  </div>
</template>

<script>
import RoleMixin from "@/Mixins/RoleMixin";
import {mapGetters} from "vuex";
import FormSaveMixin from "@/crm_components/form_save/FormSaveMixin";

export default {
  name: "Actions",
  props:{
    is_watch:{
      type: Boolean,
      default: true
    },
    data_request:{
      type:Object
    }
  },

  computed:{
    ...mapGetters({
      GET_PROFILE_INFO:'user/GET_PROFILE_INFO',
      GET_GLOBAL_SETTINGS:'user/GET_GLOBAL_SETTINGS',
    }),
    component_type(){
      if (this.is_watch){
        return 'a-dropdown'
      }else{
        if(this.take_over){
          return 'a-dropdown-button'
        }else if (this.text_chenge_status){
          return 'a-dropdown-button'
        }else{
          return 'a-dropdown'
        }
      }
    },
    item(){
      console.log('this.params', this.params)
      if (this.params){
        return this.params.data
      }
      return this.data_request
    },
    available_statuses(){
      if (this.item.responsible_manager || this.item.author){
        if (this.GET_PROFILE_INFO){
          if ((this.item.responsible_manager && this.item.responsible_manager.uid === this.GET_PROFILE_INFO.uid) ||
              (this.item.author && this.item.author.uid === this.GET_PROFILE_INFO.uid)){
            let can_set_statuses = this.GET_PROFILE_INFO.request_permissions.can_set_statuses
            let exception_current_status = can_set_statuses.filter(ele=>ele.uid !== this.item.status_request.uid)
            return exception_current_status
          }
        }
      }
    },
    text_chenge_status(){
      try {
        if (this.item.responsible_manager || this.item.author){
          if ((this.item.responsible_manager && this.item.responsible_manager.uid === this.GET_PROFILE_INFO.uid) ||
              (this.item.author && this.item.author.uid === this.GET_PROFILE_INFO.uid)){
            if (this.GET_PROFILE_INFO){
              let can_set_statuses = this.GET_PROFILE_INFO.request_permissions.can_set_statuses
              let filter_show_which_statuses_button = []
              if (this.GET_GLOBAL_SETTINGS){
                let show_which_statuses_button
                if (this.item.author.uid === this.GET_PROFILE_INFO.uid){
                  show_which_statuses_button = this.GET_GLOBAL_SETTINGS[0].show_which_statuses_button_performer
                }else{
                  show_which_statuses_button = this.GET_GLOBAL_SETTINGS[0].show_which_statuses_button_inspector
                }

                can_set_statuses.forEach((ele)=>{
                  if (show_which_statuses_button.find(element=>element.uid===ele.uid)){
                    filter_show_which_statuses_button.push(ele)
                  }
                })
              }
              let current_status_index = filter_show_which_statuses_button.findIndex(ele=>ele.sort > this.item.status_request.sort)
              if (current_status_index === -1){
                return false
              }
              let next_status = filter_show_which_statuses_button[current_status_index+1]
              console.log('next_status', next_status)
              return next_status
            }
          }
        }
      }catch (e) {
        console.log('e', e)
      }
    },
    take_over(){
      if (this.item){
        // if (this.item.author === this.GET_PROFILE_INFO.uid){
        //   return false
        // }
        if (this.item.responsible_manager === null){
          if (this.GET_PROFILE_INFO){
            let status_request = this.item.status_request.code
            let can_get_statuses = this.GET_PROFILE_INFO.request_permissions.can_get_statuses
            console.log('can_get_statuses', can_get_statuses)
            if(can_get_statuses.find(ele=>ele.code===status_request)){
              return true
            }
          }
        }
      }

    }
  },
  methods: {
    handleEdit() {
      if (this.params){
        this.params.onEditClicked(this.params)
      }
      this.$emit('onEditClicked');
    },
    handleDelete() {
      if (this.params){
        this.params.onDeleteClicked(this.params)
      }
      this.$emit('onDeleteClicked');
    },
    watch_request(){
      if (this.params){
        this.params.onWatchClicked(this.params)
      }
      this.$emit('onWatchClicked');
    },
    afterUpdate(val){
      console.log('afterUpdate val', val)
      if (this.params){
        this.params.afterUpdate(val)
      }
      this.$emit('update_request', val)
    },
    change_status(val){
      let data = {
        clientData:{
          uid:this.item.uid,
          status_request:val.uid,
        }
      }
      this.change_fun(data)
    },
    handleTakeOver(){
      let data = {
        clientData:{
          uid:this.item.uid,
          responsible_manager:this.GET_PROFILE_INFO.uid,
        }
      }
      this.change_fun(data)
    },
    change_fun(data){
      this.handleSubmit(
          data,
          null,
          `organizations/customer/`,
          'update',
      )
    },

  },
  mixins:[
    RoleMixin,
    FormSaveMixin
  ]
}
</script>

<style lang="scss">
@import "src/scss/base/_variables.scss";
.menu-color-icon{
  color: $color-primary;
  font-size: 20px;
}
//.menu-color-icon {
//  display: flex;
//  justify-content: center;
//  align-items: center;
//}
</style>
