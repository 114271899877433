<script>
import ProfileSelectView from "@/components/AntSelect/ProfileSelectView";
import RoleMixin from "@/Mixins/RoleMixin";
export default {
  name: "FormItemsUpdate",
  data(){
    return{
      ProfileSelectView,
      formItemsCreateRequest: [
        {
          model:'health_threat',
          type:'checkbox',
          show_label: false,
        },

        {
          model: 'name',
          type:'input',
          validate: 'required',
          col:2,
          vertically:true,
        },
        {
          model: 'description',
          type:'textarea',
          validate: 'required',
          col:2,
          vertically:true,
        },
        {
          model: 'categories_of_requests',
          type:'tree_select_categories',
          end_point:'organizations/categories_of_requests/',
          validate: 'required',
          col:2,
          vertically:true,
        },
        {
          model: 'request_type',
          type:'select',
          end_point:'organizations/request_types/',
          validate: 'required',
          col:2,
          vertically:true,
        },
        {
          model: 'level_request',
          type:'select',
          end_point:'organizations/levels/',
          validate: 'required',
          col:2,
          vertically:true,
        },
        {
          model: 'source_request',
          type:'select',
          end_point:'organizations/sources/',
          validate: 'required',
          col:2,
          vertically:true,
        },
        {
          model: 'region_fk',
          type:'select',
          end_point:'organizations/regions/',
          validate: 'required',
          repeat_request_event:['organization_request', 'responsible_manager'],
          col:2,
          vertically:true,
        },
        {
          model: 'organization_request',
          type:'select',
          end_point:'organizations/organizations/',
          client_data_params:['region_fk'],
          use_last_request_params_event:true,
          clear_on_change:true,
          repeat_request_event:['responsible_manager'],
          col:2,
          vertically:true,
        },
        {
          model: 'responsible_manager',
          type:'select',
          end_point:'organizations/responsible_manager/',
          select_list_component:ProfileSelectView,
          client_data_params:['organization_request', 'region_fk'],
          use_last_request_params_event:true,
          clear_on_change:true,
          col:2,
          vertically:true,
        },
        {
          model: 'parent',
          type:'select',
          end_point:'organizations/customer_select/',
          col:2,
          vertically:true,
        },
        {
          model: 'attachments',
          type:'upload',
          read_only:false,
          col:2,
          vertically:true,
        },
        {
          model:'no_address_required',
          type:'checkbox',
          show_label: false,
          in_case:true,
          hide: ['map_requests'],
        },
        {
          model: 'map_requests',
          type:'map',
          show_label: false,
          hide_if: 'no_address_required',
          validate: 'required',
        },

      ],
    }
  },
  mixins:[
    RoleMixin
  ],
  computed:{
    form_items_update(){
      // if (this.is_only_dispatcher){
      //   return [
      //     {
      //       model: 'organization_request',
      //       type:'select',
      //       end_point:'organizations/organizations/',
      //     },
      //     {
      //       model: 'responsible_manager',
      //       type:'select',
      //       end_point:'organizations/profiles/',
      //       select_list_component:ProfileSelectView,
      //     },
      //     {
      //       model: 'status_request',
      //       type:'select',
      //       end_point:'organizations/statuses/',
      //     },
      //   ]
      // }

      return [
        {
          model: 'parent',
          type:'select',
          end_point:'organizations/customer_select/',
        },
        {
          model:'health_threat',
          type:'checkbox',
          show_label: false,
        },
        {
          model: 'name',
          type:'input',
          validate: 'required',
        },
        {
          model: 'description',
          type:'textarea',
          validate: 'required',
        },
        {
          model: 'request_type',
          type:'select',
          end_point:'organizations/request_types/',
          validate: 'required',
        },
        {
          model: 'level_request',
          type:'select',
          end_point:'organizations/levels/',
          validate: 'required',
        },
        {
          model: 'source_request',
          type:'select',
          end_point:'organizations/sources/',
          validate: 'required',
        },
        {
          model: 'region_fk',
          type:'select',
          end_point:'organizations/regions/',
          validate: 'required',
          repeat_request_event:['organization_request', 'responsible_manager'],
        },
        {
          model: 'organization_request',
          type:'select',
          end_point:'organizations/organizations/',
          client_data_params:['region_fk'],
          use_last_request_params_event:true,
          clear_on_change:true,
          repeat_request_event:['responsible_manager']
        },
        {
          model: 'responsible_manager',
          type:'select',
          end_point:'organizations/responsible_manager/',
          select_list_component:ProfileSelectView,
          client_data_params:['organization_request', 'region_fk'],
          use_last_request_params_event:true,
          clear_on_change:true,
        },
        {
          model: 'status_request',
          type:'select',
          end_point:'organizations/statuses/',
        },
        {
          model: 'result_request',
          type:'select',
          end_point:'organizations/results/',
        },
        {
          model: 'attachments',
          type:'upload',
          show_label: false,
        },
        {
          model:'no_address_required',
          type:'checkbox',
          show_label: false,
          in_case:true,
          hide: ['map_requests'],
        },
        {
          model: 'map_requests',
          type:'map',
          hide_if: 'no_address_required',
          validate: 'required',
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>
