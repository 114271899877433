<template>
  <a-tabs :default-active-key="stab" @change="callback">
    <a-tab-pane :key="item.key" :tab="item.name" v-for="(item, index) in tabs">
    </a-tab-pane>
  </a-tabs>
</template>

<script>
export default {
  name: "CustomTabs",
  props:{
    tabs:{
      type:Array,
      default:[]
    },
    visible:{
      type:Boolean,
      default: false
    }
  },
  watch:{
    visible:{
      handler(val){
        if (val){
          this.set_route()
        }
      }
    }
  },
  async created() {
    await this.set_route()
  },
  methods:{
    async set_route(tab){
      if (!this.$route.query.stab && this.tabs.length>0){
        try {
          this.$router.push({query:{...this.$route.query, stab: this.tabs[0].key}})
        }catch (e) {

        }

      }
    },
    callback(key) {
      try {
        this.$router.push({query:{...this.$route.query, stab: key}})
      }catch (e) {

      }

    },
  },
  computed:{
    stab(){
      return this.$route.query.stab
    }
  }

}
</script>

<style scoped>

</style>
