<template>
  <div>
    <a-checkbox v-model="select" @change="change_checkbox" :disabled="disabled">
      {{label}}
    </a-checkbox>
  </div>
</template>

<script>
export default {
  name: "CheckboxFormSelect",
  props:{
    use_router_replace:{
      type: Boolean,
      required: false
    },
    value:{
      type: Boolean,
      default:false,
    },
    label:{
      type: String,
      required: true
    },
    name:{
      type: String,
      required: true
    },
    disabled:{
      type:Boolean,
      default:false,
    },
  },
  data(){
    return{
      select: this.value
    }
  },
  watch:{
    value(val){
      this.select = val
      this.$emit('change', {
        target:{
          checked: val
        }
      })
    }
  },
  mounted(){
    if (this.use_router_replace){
      if (this.$route.query[this.name]){
        this.select = Boolean(this.$route.query[this.name])
      }
    }
  },
  methods:{
    async change_checkbox(val){
      this.$emit('change', val)

      if (this.use_router_replace){
        let query = await {...this.$route.query}
        query[this.name] = this.select
        await this.$router.replace({
          name: this.$route.name,
          query:query
        });
      }
    }
  }

}
</script>

<style scoped>

</style>
