<template>
  <div>
    <div class="editor-toolbar">
      <a-button @click="toggleBold" title="Bold" icon="bold" />
      <a-button @click="toggleItalic" title="Italic" icon="italic" />
      <a-button @click="toggleUnderline" title="Underline" icon="underline" />
      <a-button @click="toggleStrike" title="Strikethrough" icon="strikethrough" />
      <a-button @click="toggleHeading1" title="Heading 1" class="heading-button">H1</a-button>
      <a-button @click="toggleHeading2" title="Heading 2" class="heading-button">H2</a-button>
<!--      <a-button @click="toggleBulletList" title="Bullet List" icon="unordered-list" />-->
<!--      <a-button @click="toggleOrderedList" title="Ordered List" icon="ordered-list" />-->
      <a-button @click="toggleCodeBlock" title="Code Block" icon="code" />
      <a-button @click="toggleHorizontalRule" title="Horizontal Rule" icon="line" />
      <a-button @click="undo" title="Undo" icon="undo" />
      <a-button @click="redo" title="Redo" icon="redo" />
    </div>
    <editor-content :editor="editor" />
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import {
  BoldOutlined, ItalicOutlined, UnderlineOutlined, StrikethroughOutlined, FontSizeOutlined,
  OrderedListOutlined, UnorderedListOutlined, BlockquoteOutlined, CodeOutlined, LineOutlined,
  UndoOutlined, RedoOutlined
} from '@ant-design/icons-vue'
import { Button } from 'ant-design-vue'

export default {
  name: "Tiptap",
  components: {
    EditorContent,
    'a-button': Button,
    BoldOutlined,
    ItalicOutlined,
    UnderlineOutlined,
    StrikethroughOutlined,
    FontSizeOutlined,
    OrderedListOutlined,
    UnorderedListOutlined,
    BlockquoteOutlined,
    CodeOutlined,
    LineOutlined,
    UndoOutlined,
    RedoOutlined
  },
  data() {
    return {
      editor: null,
    }
  },
  mounted() {
    this.editor = new Editor({
      content: '<p>I’m running Tiptap with Vue.js. 🎉</p>',
      extensions: [
        StarterKit,
      ],
    })
  },
  beforeDestroy() {
    this.editor.destroy()
  },
  methods: {
    toggleBold() {
      this.editor.chain().focus().toggleBold().run()
    },
    toggleItalic() {
      this.editor.chain().focus().toggleItalic().run()
    },
    toggleUnderline() {
      this.editor.chain().focus().toggleUnderline().run()
    },
    toggleStrike() {
      this.editor.chain().focus().toggleStrike().run()
    },
    toggleHeading1() {
      this.editor.chain().focus().toggleHeading({ level: 1 }).run()
    },
    toggleHeading2() {
      this.editor.chain().focus().toggleHeading({ level: 2 }).run()
    },
    toggleBulletList() {
      this.editor.chain().focus().toggleBulletList().run()
    },
    toggleOrderedList() {
      this.editor.chain().focus().toggleOrderedList().run()
    },
    toggleBlockquote() {
      this.editor.chain().focus().toggleBlockquote().run()
    },
    toggleCodeBlock() {
      this.editor.chain().focus().toggleCodeBlock().run()
    },
    toggleHorizontalRule() {
      this.editor.chain().focus().setHorizontalRule().run()
    },
    undo() {
      this.editor.chain().focus().undo().run()
    },
    redo() {
      this.editor.chain().focus().redo().run()
    },
  }
}
</script>

<style scoped>
.editor-toolbar {
  margin-bottom: 10px;
  display: flex;
  gap: 5px;
}
.editor-toolbar .heading-button {
  font-size: 14px;
  padding: 0 10px;
}
</style>
