<template>
  <a-icon :type="icon_type" style="width: 100%; font-size: 160px;"/>
</template>

<script>
export default {
  name: "BigIcon",
  props:{
    icon_type: {
      type: String,
      default: ''
    },
  }
}
</script>

<style scoped>

</style>