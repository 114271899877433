<template>
  <a-drawer
      placement="right"
      :closable="false"
      :visible="visible"
      @close="onClose"
  >
    <div
        :style="{
          position: 'absolute',
          right: 0,
          top: 0,
          height: '50px',
          width: '100%',
          borderBottom: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'left',
          zIndex: 1,
        }"
    >
      <div class="title_draw_text">
        <slot name="title"></slot>
      </div>
    </div>
    <div
        :style="{
          position: 'absolute',
          right: 0,
          top: 0,
          marginTop: '50px',
          width: '100%',
          borderBottom: '1px solid #e9e9e9',
          background: '#fff',
          textAlign: 'left',
          padding: '0px 20px',
          zIndex: 1,
        }"
    >
      <slot name="tabs"></slot>
    </div>
    <div >
      <slot name="content"></slot>
    </div>

    <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'left',
          zIndex: 1,
        }"
    >
      <slot name="content_bottom"></slot>
    </div>
  </a-drawer>
</template>

<script>
export default {
  name: "CustomDrawer",
  props:{
    visible:{
      type:Boolean,
      default:false,
    },
    title:{
      type:String,
      default:undefined,
    },
    key_delete_router:{
      type:Array,
      default:[]
    }
  },
  data() {
    return {

    };
  },
  methods: {
    async onClose() {
      try {
        this.$emit('close')
        let route = {...this.$route.query}
        this.key_delete_router.forEach((item)=>{
          delete route[item]
        })
        await this.$router.push({query:{...route}})
      }catch (e) {

      }

    },
  },
}
</script>

<style>

@media (max-width: 992px) {
  .ant-drawer-content-wrapper{
    /*z-index: 10000000 !important;*/
    width: 100% !important;
  }
}
@media (min-width: 992px) {
  .ant-drawer-content-wrapper{
    /*z-index: 10000000 !important;*/
    width: 1300px !important;
  }
}
.ant-tabs-bar{
  margin: 0 0 0 0 !important;
}
</style>
