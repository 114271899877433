<script>
export default {
  name: "validate_email",
  methods:{
    validateEmail(rule, value, callback) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (value === undefined || value === ''){
        this.validateMessage = '';
        callback();
      }

      if (!emailRegex.test(value)) {
        this.validateMessage = this.$t('error_massage.email');
        callback(this.validateMessage);
      } else {
        this.validateMessage = '';
        callback();
      }
    }
  }
}
</script>

<style scoped>

</style>