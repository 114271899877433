<script>
import ActionsRequest from "./ActionsRequest";
import NameRequest from "./NameRequest";
import AgGridTableNumber from "@/components/Forms/FormAddClient/AgGridTableNumber";
export default {
  name: "ColumnDefsCreateRequest",
  components:{
    ActionsRequest,
    NameRequest,
    AgGridTableNumber
  },
  data(){
    return{
      column_defs_create_request: [

        {
          headerName: this.$t('name'),
          title: this.$t('name'),
          headerSort: false,
          field: "name",
          minWidth: 150,
          cellClass: ['a-link', 'cursor-pointer'],
          open_drawer:true,
          formatter:function(cell, formatterParams){
            var value = cell.getValue();
            return "<span class='a-link cursor-pointer'>" + value + "</span>";
          },
        },
        // {
        //   headerName: "№",
        //   title: "№",
        //   headerSort: false,
        //   field: "full_name",
        //   minWidth: 75,
        //   maxWidth: 75,
        //   cellRendererFramework: 'AgGridTableNumber',
        // },
        // {
        //   headerName: "",
        //   title: "",
        //   headerSort: false,
        //   field: "name",
        //   cellRendererFramework: 'ActionsRequest',
        //   minWidth: 75,
        //   maxWidth: 75,
        //   cellClass: ['flex justify-center items-center h-full'],
        //   cellRendererParams: {
        //     onEditClicked: params => {
        //       this.open_drawer_request({data:params.data}, true)
        //     },
        //     onDeleteClicked: params => {
        //       this.delete_method('create_request', 'organizations/customer/', params.data)
        //     },
        //     onWatchClicked: params => {
        //       this.open_drawer_request({data:params.data})
        //     },
        //     afterUpdate: params => {
        //       this.update_request(params)
        //     },
        //   }
        // },
        {
          headerName: "#",
          title: "#",
          headerSort: false,
          field: "request_number_v2",
          minWidth: 100,
          maxWidth: 100,
        },
        {
          headerName: "Дата",
          title: "Дата",
          headerSort: false,
          field: "created",
          // headerCheckboxSelection: true,
          // headerCheckboxSelectionFilteredOnly: true,
          // checkboxSelection: true,
          valueFormatter: function(params) {
            // Получаем дату из параметров
            var date = params.value;
            // Парсим дату в объект Date
            var formattedDate = new Date(date);
            // Получаем день, месяц и год
            var day = formattedDate.getDate().toString().padStart(2, '0');
            var month = (formattedDate.getMonth() + 1).toString().padStart(2, '0'); // Месяц начинается с 0
            var year = formattedDate.getFullYear().toString();
            // Формируем строку в формате "dd/MM/yyyy"
            var formattedDateString = `${day}/${month}/${year}`;
            // Возвращаем отформатированную дату
            return formattedDateString;
          },
          minWidth: 150,
        },
        // {
        //   headerName: "ФИО",
        //   field: "client.profile.full_name"
        // },
        {
          headerName: this.$t('client'),
          title: this.$t('client'),
          headerSort: false,
          field: "client.profile.full_name",
        },
        {
          headerName: this.$t('request_type'),
          title: this.$t('request_type'),
          headerSort: false,
          field: "request_type.name",
          minWidth: 150,
        },
        {
          headerName: this.$t('days_until_due'),
          title: this.$t('days_until_due'),
          headerSort: false,
          field: "days_until_due",
          cellClassRules: {
            'red-background': params => Number(params.value) <= 0
          }
        },
        {
          headerName: this.$t('status'),
          title: this.$t('status'),
          headerSort: false,
          field: "status_request.name",
          minWidth: 150,
        },
        {
          headerName: this.$t('organization'),
          title: this.$t('organization'),
          headerSort: false,
          field: "organization_request.name",
          minWidth: 150,
        },
        {
          headerName: this.$t('result'),
          title: this.$t('result'),
          headerSort: false,
          field: "result_request.name",
          minWidth: 150,
        },
        {
          headerName: this.$t('level'),
          title: this.$t('level'),
          headerSort: false,
          field: "level_request.name",
          minWidth: 150,
        },
      ],
    }
  }
}
</script>

<style>
.red-background{
  background-color: #ffeaea;
}
</style>
