<template>
  <div>
    <info-drawer v-for="(item, index) in description_info" :key="index" :title="item.title" :border_bottom="index !== description_info.length - 1">
      <template slot="content">
        {{ item.value }}
      </template>
    </info-drawer>
  </div>
</template>

<script>
import InfoDrawer from "@/crm_components/CustomDrawer/InfoDrawer";
import DescriptionInfoItem from "@/crm_components/description/DescriptionInfoItem";
export default {
  name: "DescriptionInfoRequest",
  components: {DescriptionInfoItem, InfoDrawer},
  props:{
    data: {
      type: Object,
      default: {}
    }
  },
  computed:{
    description_info(){
      let data = []
      if (this.data){
        if(this.data.level_request){
          data.push({
            title: this.$t('level_request'),
            value: this.data.level_request.name
          })
        }
        if(this.data.organization_request){
          data.push({
            title: this.$t('organization_request'),
            value: this.data.organization_request.name
          })
        }
        if(this.data.request_type){
          data.push({
            title: this.$t('request_type'),
            value: this.data.request_type.name
          })
        }
        if(this.data.status_request){
          data.push({
            title: this.$t('status_request'),
            value: this.data.status_request.name
          })
        }
        if(this.data.source_request){
          data.push({
            title: this.$t('source_request'),
            value: this.data.source_request.name
          })
        }
        if(this.data.result_request){
          data.push({
            title: this.$t('result_request'),
            value: this.data.result_request.name
          })
        }
        console.log('data', data)

        return data
      }
    }
  }
}
</script>

<style scoped>

</style>
