<template>
  <div class="ag-theme-alpine card-ag-grid" >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Card"
}
</script>

<style lang="scss">
@import "src/scss/base/_variables.scss";
.card-ag-grid{
  border-radius: $border-radius-base;
  border: 5px solid #e8e8e8 !important;
  background: #f8f8f8;
}

</style>