import { render, staticRenderFns } from "./AgGridTableNumber.vue?vue&type=template&id=0cd1582c&scoped=true"
import script from "./AgGridTableNumber.vue?vue&type=script&lang=js"
export * from "./AgGridTableNumber.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cd1582c",
  null
  
)

export default component.exports