<template>
  <div class="flex justify-center items-center h-full">
    <a-dropdown :trigger="['click']">
      <a-icon type="menu" class="menu-color-icon text-center cursor-pointer"/>
      <a-menu slot="overlay">
        <a-menu-item>
          <a @click="watch_request">
            {{$t('watch')}}
          </a>
        </a-menu-item>
        <a-menu-item @click="handleEdit">
          <a>
            {{$t('edit')}}
          </a>
        </a-menu-item>
        <a-menu-item @click="handleDelete" v-if="is_operator">
          <a>
            {{$t('delete')}}
          </a>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
  </div>
</template>

<script>
import RoleMixin from "@/Mixins/RoleMixin";

export default {
  name: "Actions",
  methods: {
    handleEdit() {
      this.params.onEditClicked(this.params)
      this.$emit('onEditClicked');
    },
    handleDelete() {
      this.params.onDeleteClicked(this.params)
      this.$emit('onDeleteClicked');
    },
    watch_request(){
      this.params.onWatchClicked(this.params)
      this.$emit('onWatchClicked');
    }
  },
  mixins:[
    RoleMixin
  ]
}
</script>

<style lang="scss">
@import "src/scss/base/_variables.scss";
.menu-color-icon{
  color: $color-primary;
  font-size: 20px;
}
</style>
