<template>
  <div class="form-card-v2">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "CardV2",
}
</script>

<style lang="scss">
@import "src/scss/base/_variables.scss";

.form-card-v2{
  background: #fafafa;
  color: rgba(0, 0, 0, 0.65);
  border-radius: $border-radius-base;
  padding: 20px;
}
</style>
