<script>
export default {
  name: "OpenDrawerClientMixin",
  methods:{
    async open_drawer(data, edit = false){
      console.log('data', data)

      this.$refs.refClientShowDrawer.data = data
      try {
        await this.$router.push({query:{...this.$route.query, client: data.data.uid}})
      }catch (e) {
      }
      if (edit){
        this.$refs.refClientShowDrawer.edit()
      }else{
        this.$refs.refClientShowDrawer.visible = await true
        this.$refs.refClientShowDrawer.visible_edit = await false
      }

      this.$refs.refRequestShowDrawer.visible = false
      this.$refs.refRequestShowDrawer.visible_edit = false

      console.log('this.$refs.refAgGridCreateRequest', this.$refs.refClientShowDrawer.$refs.refClient.$refs.refAgGridCreateRequest)
      if (this.$refs.refClientShowDrawer.$refs.refClient.$refs.refAgGridCreateRequest){
        this.$refs.refClientShowDrawer.$refs.refClient.$refs.refAgGridCreateRequest.methods_mounted(true)
      }
    },
  }
}
</script>

<style scoped>

</style>
