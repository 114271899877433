import axios from '@/configs/axios';

const state = () => ({
    statuses: [],
});

const getters = {
    GET_STATUSES: s => s.statuses,
};

const mutations = {
    SET_STATUS(state, value){
        if(state.statuses) {
            state.statuses.push(...value.results)
        }else{
            state.statuses = value.results
        }
    },
    SET_CLEAR(state){
        state.statuses = []
    }

};

const actions = {
    async STATUS({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.get('organizations/statuses/', {params:payload})
                .then(response => {
                    commit('SET_STATUS', response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    async KANBAN_LIST({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.get('organizations/kanban_list/', {params:payload})
                .then(response => {
                    commit('SET_KANBAN_LIST', response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
