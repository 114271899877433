<script>
import moment from 'moment';
export default {
  name: "FormattedDateMixin",
  methods:{
    formattedDate(date_time) {
      const date = moment(date_time).format('DD.MM.YYYY');
      const time = moment(date_time).format('HH:mm');
      return `${date} ${this.$t('at')} ${time}`;
    },
  }
}
</script>
