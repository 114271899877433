<template>
    <a-button class="button">
      <a-icon type="more" style="font-size: 20px"/>
    </a-button>
</template>

<script>
export default {
  name: "ColorIcon"
}
</script>

<style scoped lang="scss">
@import "src/scss/base/_variables.scss";
.button{
  background: $color-primary;
  padding: 0 10px;
  color: $color-white;
}
</style>