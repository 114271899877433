<template>
  <div :class="['sidebar_item', { 'no-border': !border_bottom }]">
    <div class="title" v-if="title">
      {{ title }}
    </div>
    <div>
      <slot name="content"/>
    </div>
  </div>
</template>


<script>
export default {
  name: "InfoDrawer",
  props:{
    info_data:{
      type:Object,
      default:{}
    },
    title:{
      type:String,
      default: null,
    },
    border_bottom:{
      type:Boolean,
      default: true,
    }
  },
}
</script>

<style scoped lang="scss">
@import "src/scss/base/_variables.scss";

.title {
  font-weight: 600;
  margin-bottom: 0.25rem;
  font-size: 15px;
  line-height: 1.25rem;
}

.sidebar_item {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #f1f1f1;
}

.no-border {
  border-bottom: none;
}
</style>
