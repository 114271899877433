<!--
	This is the main page of the application, the layout component is used here,
	and the router-view is passed to it.
	Layout component is dynamically declared based on the layout for each route,
	specified in routes list router/index.js .
 -->

<template>
	<div id="app">
    <router-view />
    <RequestShowDrawer ref="refRequestShowDrawer"/>
    <ClientAndRequest ref="refClientAndRequest" :title="$t('add_request_text')"/>
    <ClientShowDrawer ref="refClientShowDrawer"/>
	</div>
</template>

<script>
import RequestShowDrawer from "@/pages/AddClient/Request/RequestShowDrawer";
import ClientAndRequest from "@/pages/AddClient/ClientAndRequest/ClientAndRequest";
import ClientShowDrawer from "@/pages/AddClient/Client/ClientShowDrawer";
	export default ({
    components:{
      RequestShowDrawer,
      ClientAndRequest,
      ClientShowDrawer,
    },
		computed: {
			// Sets components name based on current route's specified layout, defaults to
			// <layout-default></layout-default> component.
			layout() {
				return "layout-" + ( this.$route.meta.layout || "default" ).toLowerCase() ;
			}
		},
    mounted() {
      this.changePageTitle('');
    },
    methods: {
      changePageTitle(title) {
        document.title = title;
      }
    }
	})

</script>

<style lang="less">
@import "~@/assets/theme.less";
@import  "~@/components/CustomTabular/tabulator-master/dist/css/tabulator_semanticui.css";
//@import  "~@/components/tabulator-tables/dist/css/tabulator.min.css";
</style>
<!--<style lang="scss">-->
<!--@import "~@/scss/app.scss";-->
<!--</style>-->
