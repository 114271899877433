<template>
<div class="flex">
  <div style="margin-top: -5px;" v-if="item.parent" :class="item.parent?`ml-${item.level}`:''">
    <a-icon type="enter" style="transform: scaleX(-1); color: black;" class="" />
  </div>
  <div :class="item.parent?`ml-2`:''">
    {{item.name}}
  </div>
</div>
</template>

<script>
export default {
  name: "NameRequest",
  computed:{
    item(){
      return this.params.data
    },
  }

}
</script>



<style scoped lang="scss">

</style>