<script>
import {mapActions} from "vuex";

export default {
  name: "validate_iin",
  methods:{
    ...mapActions({
      CHECK_IIN_EXISTS:'client/CHECK_IIN_EXISTS',
    }),
    async validateIIN(rule, value, callback=null){
      const numericRegex = /^\d+$/;
      console.log('value', value)
      if (value === undefined || value === ''){
        this.validateMessage = '';
        callback();
      }

      if (value.length !== 12 && value !=='') {
        this.validateMessage = this.$t('error_massage.iin_12');
        callback(this.validateMessage);
      } else if (!numericRegex.test(value)) {
        this.validateMessage = this.$t('error_massage.iin_numeric');
        callback(this.validateMessage);
      } else {
        await this.CHECK_IIN_EXISTS({iin:value})
          .then( async (res)=>{
            this.validateMessage = '';
            callback();
          })
          .catch(async (e)=>{
            console.log('error')
            this.validateMessage = this.$t('error_massage.iin_exists');
            callback(this.validateMessage);
          })

      }
      callback();
    }
  }
}
</script>

<style scoped>

</style>
