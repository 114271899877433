<template>
  <div style="height: 100%;">
    <search class="p-2" @updated_data="get_data(true)" :query_name="query_name"/>
    <div>
      <ag-grid-vue
          :rowData="data"
          ref="agGridTable"
          :gridOptions="gridOptions"
          :animateRows="true"
          @grid-ready="onGridReady"
          @sort-changed="onSortChanged"
          style="width: 100%;"
          :rowSelection="rowSelection"
          class="ag-theme-alpine"
          :columnDefs="columnDefs"
          colResizeDefault="shift"
          :sideBar="'columns'"
          :style="{'height':ag_grid_height}"
      ></ag-grid-vue>
    </div>

    <div class="p-3">
      <a-pagination
          @change="change_page"
          v-model="current"
          :page-size="20"
          :total="total">

      </a-pagination>
    </div>
  </div>

</template>


<script>
import { AgGridVue } from "ag-grid-vue";
import "../../AgGrid/styles/ag-grid.scss";
import "../../AgGrid/styles/ag-theme-alpine/sass/ag-theme-alpine.scss";
import axios from "@/configs/axios";
import {EventBus} from "@/configs/eventBus";
import Search from "@/crm_components/search/Search";

export default {
  name: "Page",
  components:{
    AgGridVue,
    Search,
  },
  beforeMount() {
    this.rowSelection = 'multiple';
  },
  data(){
    return{
      rowSelection: null,
      current: 1,
      save_data:[],
      data:[],
      total:0,
      gridApi: null,
      gridOptions: {
        onCellClicked:this.on_cell_double_clicked,
        defaultColDef: {
          sortable: true,
          filter: true,
          resizable: true
        }
      },
      searchTimeout: null, // Добавляем searchTimeout в data
    }
  },
  props:{
    column_defs:{
      type:Array,
      default: null
    },
    end_point: {
      type: String,
      default: ''
    },
    ag_grid_height: {
      type: String,
      default: '450px'
    },
    name_event_bus: {
      type: String,
      default: ''
    },
    click_column:{
      type:String,
      default:'profile.full_name',
    },
    visible:{
      type:Boolean,
      default:false,
    },
    request_mounted:{
      type:Boolean,
      default:true,
    },
    show_subtasks:{
      type:Boolean,
      default:false,
    },
    query_name:{
      type:String,
      default:'search',
    },
    query_page_name:{
      type:String,
      default:'page',
    },
    query_sort_name:{
      type:String,
      default:'sort',
    },
    updated_event_bus:{
      type: String,
      default: ''
    },
    list_keys_query_params:{
      type:Array,
      default: []
    }
  },
  computed:{
    columnDefs(){
      if (this.column_defs){
        let index_e = this.column_defs.findIndex(ele=>ele.headerName === '№')
        let clone = [...this.column_defs]
        if (index_e !== -1){
          clone[index_e]['query_page_name'] = this.query_page_name
        }
        return clone
      }
    }
  },
  watch:{
    // visible(val){
    //   console.log('testtsttsts')
    //   this.methods_mounted()
    // }
    // async current(val) {
    //     await this.change_router(val)
    //     await this.get_data()
    // },
  },
  async mounted() {
    if (this.request_mounted){
      await this.methods_mounted()
    }
    if (this.show_subtasks){
      let clone_data = []
      this.data.forEach(async (ele)=>{
        await clone_data.push(ele)
        if (ele.subtasks_count>0){
          await this.function_subtasks(ele, 1).then(async (res)=>{
            await res.forEach(async (elem)=>{
              await clone_data.push(elem)
              if (elem.subtasks_count>0){
                await this.function_subtasks(elem, 3).then(async (res2)=>{
                  await res2.forEach(async (eleme)=>{
                    await clone_data.push(eleme)
                  })
                })
              }

            })
          })
        }
      })
      console.log('clone_data', clone_data)
      this.data = clone_data

    }
  },
  created() {
    EventBus.$on(this.updated_event_bus, this.updated_data_event);
    EventBus.$on(this.name_event_bus, this.updateMessage);
    EventBus.$on(this.name_event_bus+"_delete", this.deleteData);
  },
  beforeDestroy() {
    EventBus.$off(this.updated_event_bus);
    EventBus.$off(this.name_event_bus, this.updateMessage); // Удаляем прослушиватель событий
    EventBus.$off(this.name_event_bus+"_delete", this.deleteData);
  },
  methods:{
    function_subtasks(params, level) {
      console.log('level', level)
      return new Promise((resolve, reject) => {
        this.function_to_get_list({parent:params.uid}).then(async (res)=>{
          res = res.data
          let clone_results = res.results.map((elem)=>{
            return {
              ...elem,
              level:level
            }
          })
          resolve(clone_results)
        })
      })
    },
    async updated_data_event(){
      console.log('updated_data  fgegegregege')
      await this.get_data(true)
    },
    deleteData(payload){
      console.log('payload', payload)
      let clone_data = this.data.filter(item=>item.uid !== payload.uid)

      this.data = clone_data
      // const index = this.save_data.findIndex(item=>item.page == this.$route.query[this.query_page_name])
      // this.save_data[index].data = this.data
    },
    updateMessage(payload) {
      const index_data = this.data.findIndex(item=>item.uid === payload.uid)
      let clone_data = [...this.data]

      if (index_data !== -1){
        clone_data[index_data] = payload
      }else{
        clone_data = [payload, ...clone_data]
      }
      this.data = clone_data
      // const index = this.save_data.findIndex(item=>item.page == this.$route.query[this.query_page_name])
      // this.save_data[index].data = this.data
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.sizeColumnsToFit();
    },
    async change_page(page){
      this.current = page
      await this.change_router(page)
      await this.get_data()
    },
    async change_router(page){
      try {
        let queries = {...this.$route.query,};
        queries[this.query_page_name] = page
        await this.$router.push({query: queries});
      }catch (e) {

      }

    },
    async methods_mounted(updated=false){
      if (!this.$route.query[this.query_page_name]){
        await this.change_router(this.current)
      }else{
        this.current = Number(this.$route.query[this.query_page_name])
      }

      console.log('this.$route.query.sort', this.$route.query.sort)
      // Применение сортировки из параметров маршрута, если она есть
      if (this.$route.query[this.query_sort_name]) {
        const sortParams = this.$route.query[this.query_sort_name].split(';').map(sortItem => {
          const sortOrder = sortItem.startsWith('-') ? 'desc' : 'asc';
          const colId = sortOrder === 'desc' ? sortItem.substring(1) : sortItem;
          return { colId, sort: sortOrder };
        });
        console.log('sortParams', sortParams)
        // Применяем сортировку в gridOptions
        this.gridOptions.api.setSortModel(sortParams);
      }

      await this.get_data(updated)
    },
    async get_data(updated=false){
      console.log('updated', updated)
      if (updated){
        this.current = 1
        await this.change_page(1)
        // this.save_data = []
        await this.updated_data()
      }else{
        await this.updated_data()
        // let page = this.$route.query[this.query_page_name]
        // let save_data_find = this.save_data.find(item=>item.page==page)
        // if (save_data_find){
        //   this.data = await save_data_find.data
        // }else{
        //   let res = await this.updated_data()
        //   this.save_data.push({
        //     page:String(page),
        //     data:res.results,
        //   })
        // }
      }
    },
    function_to_get_list(params){
      return axios.get(`${this.end_point}`, {params:params} )
    },
    async updated_data(){
      let r_data
      let clone_route_name = {}
      if (this.list_keys_query_params && this.list_keys_query_params.length > 0){
        // Проходим по массиву list_keys_query_params и добавляем соответствующие ключи
        this.list_keys_query_params.forEach(key => {
          if (this.$route.query[key] !== undefined) {
            clone_route_name[key] = this.$route.query[key]
          }
        })
      }else{
        clone_route_name = this.$route.query
      }

      let query_name = {
        ...clone_route_name,
        page: this.$route.query[this.query_page_name],
        sort: this.$route.query[this.query_sort_name],
        search: this.$route.query[this.query_name],
      }
      // let queries = {...this.$route.query,};
      await this.function_to_get_list(query_name)
          .then(async (res)=>{
            res = res.data
            this.data = await res.results
            this.total = await res.count
            r_data = await res
          })

      return r_data
    },
    async on_cell_double_clicked(event){
      if (event.colDef.open_drawer){
        this.$emit('open_drawer', {data: event.data})
      }
    },
    onSortChanged(event) {
      // Получаем текущую модель сортировки
      const sortModel = event.api.getSortModel();

      // Добавляем модель сортировки в параметры запроса
      const sortParams = sortModel.map((sort) => {
        if (sort.sort === 'asc') {
          return `${sort.colId}`;
        }else{
          return `-${sort.colId}`;
        }
      }).join(';');

      // Обновляем параметры маршрута с сортировкой
      let queries = {...this.$route.query,};
      queries[this.query_sort_name] = sortParams
      try {
        this.$router.push({query: queries});
      }catch (e) {

      }


      // Запрашиваем обновленные данные с сортировкой
      this.get_data();
    },
  }
};
</script>

<style lang="scss">
@import "src/scss/base/_variables.scss";
.ag-root-wrapper {
  border-radius: $border-radius-base;
  border: 0px !important;
  background: #fff;

}
</style>
