<template>
  <div>
    <a-range-picker v-model="date_start_end" @change="onChangeDateStart" />
  </div>
</template>

<script>
export default {
  name: "DateStartEnd",
  props:{
    use_router_replace:{
      type: Boolean,
      required: false
    },
    value:{
      type: Array,
      required: false
    }
  },
  data(){
    return{
      date_start_end: this.value
    }
  },
  mounted(){
    if (this.use_router_replace){
      if (this.$route.query.start && this.$route.query.end){
        this.date_start_end = [this.$route.query.start, this.$route.query.end]
      }
    }
  },
  methods:{
    async onChangeDateStart(dates, dateStrings, item) {
      this.$emit('change', dateStrings)
      console.log(dateStrings);
      let query = await {...this.$route.query}
      query['start'] = dateStrings[0]
      query['end'] = dateStrings[1]
      await this.$router.replace({
        name: this.$route.name,
        query:query
      });
    },
  }
}
</script>

<style scoped>

</style>
