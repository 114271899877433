<script>
import { mapGetters } from "vuex";

export default {
    name: "RoleMixin",
    computed:{
        ...mapGetters({
          GET_PROFILE_INFO:'user/GET_PROFILE_INFO'
        }),
        user() {
            return this.$store.state.profile
        },
        is_operator() {
          if (this.GET_PROFILE_INFO){
            if (this.GET_PROFILE_INFO.roles.find(role => role.code === 'operator')){
              return true
            }
          }
          return false
        },
        is_only_dispatcher() {
          if (this.GET_PROFILE_INFO){
            if (this.GET_PROFILE_INFO.roles.find(role => role.code === 'dispatcher') && this.GET_PROFILE_INFO.roles.length === 1){
              return true
            }
          }
          return false
        },
    }
};
</script>

<style scoped>

</style>
