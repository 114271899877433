<template>
  <div>
    <a-timeline>
      <a-timeline-item v-for="(item, index) in data" :key="index">
        <div data-v-6f0a49da="" data-show="true" class="ant-alert ant-alert-info ant-alert-no-icon">
          <div>
            {{$t('date')}}: {{moment(item.action_date).format('DD.MM.YYYY HH:mm')}}
          </div>
          <div>
            {{$t('action')}}: {{$t(item.action_id)}}
          </div>
          <div>
            {{$t('object_property')}}: {{$t(item.object_property)}}
          </div>
          <div v-if="item.before">
            {{$t('before')}}: {{$t(item.before)}}
          </div>
          <div v-if="item.after">
            {{$t('after')}}: {{$t(item.after)}}
          </div>
        </div>
      </a-timeline-item>
    </a-timeline>
  </div>
</template>

<script>
import moment from "moment/moment";

export default {
  name: "HistoryChange",
  computed: {
    moment() {
      return moment
    }
  },
  props:{
    data:{
      type:Array
    }
  }
}
</script>

<style scoped lang="scss">

</style>