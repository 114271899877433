<template>
  <div class="card_drawer">
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  name: "CardDrawer"
}
</script>

<style lang="scss">
@import "src/scss/base/_variables.scss";
.card_drawer{
  padding: 15px;
  background: #eeffe887;
}
</style>