<template>
  <div>
<!--    <card>-->
      <custom-tabular :use_search="false" :use_pagination="false" ref="refCustomTabular" :column_defs="column_defs_create_request" />
<!--    </card>-->
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import CustomTabular from "@/components/CustomTabular/CustomTabular.vue";
import Card from "@/crm_components/card/Card.vue";

export default {
  name: "TableRequest",
  components: {Card, CustomTabular},
  data(){
    return{
      column_defs_create_request: [
        {
          headerName: "#",
          title: "#",
          use:true,
          headerSort: false,
          field: "request_number_v2",
          minWidth: 100,
          maxWidth: 100,
        },
        {
          headerName: this.$t('name'),
          title: this.$t('name'),
          headerSort: false,
          use:true,
          field: "name",
          minWidth: 150,
          cellClass: ['a-link', 'cursor-pointer'],
          open_drawer:true,
          formatter:function(cell, formatterParams){
            var value = cell.getValue();
            return "<span class='a-link cursor-pointer'>" + value + "</span>";
          },
        },
        {
          headerName: "Дата",
          title: "Дата",
          headerSort: false,
          use:true,
          field: "created",
          minWidth: 150,
        },
        {
          headerName: this.$t('status'),
          title: this.$t('status'),
          headerSort: false,
          use:true,
          field: "status_request.name",
          minWidth: 150,
        },
        {
          headerName: this.$t('result'),
          title: this.$t('result'),
          headerSort: false,
          use:true,
          field: "result_request.name",
          minWidth: 150,
        },
      ],
    }
  },
  computed:{
    ...mapGetters({
      GET_PAGES_FRONT:'user/GET_PAGES_FRONT'
    }),
    con_fig(){
      console.log('this.GET_PAGES_FRONT', this.GET_PAGES_FRONT)
      if (this.GET_PAGES_FRONT){
        return this.GET_PAGES_FRONT.find(ele=>ele.code==='customer_request')
      }
    }
  },
  methods:{

  },
}
</script>

<style scoped lang="scss">

</style>