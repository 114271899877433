<template>
  <div style="height: 100%; width: 100%;">
    <form-add-client-component v-if="show_form" :name_event_bus="name_event_bus" @save_handle_submit="save_handle_submit" ref="refFormAddClient" :end_point="end_point_form" :formItems="formItems" :icon_type="icon_type">
      <template v-slot:form-card-text>
        <slot name="form-card-text"></slot>
      </template>
    </form-add-client-component>
    <ag-grid-table
        :name_event_bus="name_event_bus"
        :column_defs="column_defs"
        :end_point="end_point_ag_grid"
        :ag_grid_height="ag_grid_height"/>
  </div>
</template>

<script>
import FormAddClientComponent from "@/components/Forms/FormAddClient/FormAddClientComponent";
import AgGridTable from "@/components/Forms/FormAddClient/AgGridTable";
export default {
  name: "FormAndAgGrid",
  components: {AgGridTable, FormAddClientComponent},
  props:{
    column_defs:{
      type:Array,
    },
    formItems:{
      type:Array,
    },
    end_point_form: {
      type: String,
      default: ''
    },
    name_event_bus: {
      type: String,
      default: ''
    },
    end_point_ag_grid:{
      type: String,
      default: ''
    },
    icon_type:{
      type: String,
      default: ''
    },
    show_form:{
      type:Boolean,
      default: true
    },
    ag_grid_height: {
      type: String,
      default: '450px'
    },
  },
  methods:{
    save_handle_submit(){
      this.$emit('save_handle_submit')
    }
  }
}
</script>

<style scoped>

</style>