var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-drawer',{attrs:{"placement":"right","closable":false,"visible":_vm.visible},on:{"close":_vm.onClose}},[_c('div',{style:({
        position: 'absolute',
        right: 0,
        top: 0,
        height: '50px',
        width: '100%',
        borderBottom: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#fff',
        textAlign: 'left',
        zIndex: 1,
      })},[_c('div',{staticClass:"title_draw_text"},[_vm._t("title")],2)]),_c('div',{style:({
        position: 'absolute',
        right: 0,
        top: 0,
        marginTop: '50px',
        width: '100%',
        borderBottom: '1px solid #e9e9e9',
        background: '#fff',
        textAlign: 'left',
        padding: '0px 20px',
        zIndex: 1,
      })},[_vm._t("tabs")],2),_c('div',[_vm._t("content")],2),_c('div',{style:({
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#fff',
        textAlign: 'left',
        zIndex: 1,
      })},[_vm._t("content_bottom")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }