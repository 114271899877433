<script>
import axios from "@/configs/axios";
import {EventBus} from "@/configs/eventBus";

export default {
  name: "FormSaveMixin",
  data(){
    return {
      data: null,
    }
  },
  methods: {
    function_to_get_list(val){
      if (val.action === 'create'){
        return axios.post(`${val.end_point}`, val.data)
      } else if (val.action === 'update'){
        return axios.patch(`${val.end_point}${val.data.uid}/`, val.data)
      } else if (val.action === 'delete'){
        return axios.delete(`${val.end_point}${val.data.uid}/`, )
      }
    },
    afterCreate(val){
      val.refForm.clientData = {}
      this.$emit('after_create')
    },
    afterUpdate(val){
    },
    replaceUndefinedWithNull(dataObj) {
      const newData = {};
      for (let key in dataObj) {
        if (dataObj.hasOwnProperty(key)) {
          newData[key] = dataObj[key] === undefined ? null : dataObj[key];
        }
      }
      return newData;
    },
    async get_valid(refForm){
      let clone_valid = true
      console.log('refForm', refForm)
      if (refForm){
        if (refForm.$refs){
          if (refForm.$refs.refValidationObserver){

            await refForm.$refs.refValidationObserver.validate().then(async (valid) => {
              clone_valid = await valid
            })
          }
        }
      }
      return clone_valid
    },
    getChanges(obj1, obj2) {
      const changes = {
        'oldValue':{},
        'newValue':{},
      };

      // Получить все ключи из обоих объектов
      const allKeys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);

      allKeys.forEach(key => {
        if (obj1[key] !== obj2[key]) {
          if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object' && obj1[key] !== null && obj2[key] !== null) {
            // Если значения - это объекты, рекурсивно вызвать getChanges
            const nestedChanges = this.getChanges(obj1[key], obj2[key]);
            console.log('nestedChanges', nestedChanges)
            if (Object.keys(nestedChanges).length > 0) {
              changes[key] = nestedChanges;
            }
          } else {
            changes['oldValue'][key] = obj1[key]
            changes['newValue'][key] = obj2[key]
            // Указать старое и новое значение
            // changes[key] = {
            //   oldValue: obj1[key],
            //   newValue: obj2[key]
            // };
          }
        }
      });

      return changes;
    },
    async handleSubmit(refForm, name_event_bus, end_point, action = 'create', data= null, valid_additionally= true){
      // refForm.form.validateFields((err, values) => {
      //   if (!err) {
      //
      //   } else {
      //     console.log('error')
      //   }
      // });

      let clone_get_valid = await this.get_valid(refForm)
      console.log('clone_get_valid', clone_get_valid)

      if (!clone_get_valid){
        this.$message.error('error')
        return false
      }
      if (!valid_additionally){
        this.$message.error('error')
        return false
      }

      // Форма прошла валидацию, выполняем необходимые действия
      // Например, отправка данных на сервер
      try {
        let dataToSend
        if (refForm){
          dataToSend = this.replaceUndefinedWithNull(refForm.clientData);
        }else{
          dataToSend = data
        }
        // let initial_form = refForm?refForm.initial_form:null
        // let clientData = refForm?refForm.clientData:null
        this.function_to_get_list({
          data:{...dataToSend},
          params:{...this.$route.query},
          end_point:end_point,
          action:action,
        }).then((responce) => {
          this.$message.success(this.$t('success'));

          if (action === 'create'){
            this.afterCreate({data: responce.data, refForm: refForm, responce:responce})
            if (name_event_bus){
              EventBus.$emit(name_event_bus, {data: responce.data, refForm: refForm, responce:responce});
            }
          }else if (action === 'update'){
            this.afterUpdate({data: responce.data, refForm: refForm, responce:responce})
            if (name_event_bus){
              EventBus.$emit(name_event_bus, {data: responce.data, refForm: refForm, responce:responce});
            }
          }else if (action === 'delete'){
            EventBus.$emit(name_event_bus, dataToSend);
          }

        }).catch((error) => {
          this.$message.error('error')
          console.log('error', error)
        })
      } catch (error) {
        console.error('Ошибка отправки формы:', error);
      }


      // refForm.$validator.validateAll().then(async (result) => {
      //   if (result){
      //     let dataToSend
      //     if (refForm){
      //       dataToSend = this.replaceUndefinedWithNull(refForm.clientData);
      //     }else{
      //       dataToSend = data
      //     }
      //
      //     // console.log('refForm.clientData', refForm.clientData)
      //     this.function_to_get_list({
      //       data:{...dataToSend},
      //       params:{...this.$route.query},
      //       end_point:end_point,
      //       action:action,
      //     }).then((responce) => {
      //       this.$message.success(this.$t('success'));
      //       if (action === 'delete'){
      //         EventBus.$emit(name_event_bus, dataToSend);
      //       }else{
      //         if (name_event_bus){
      //           EventBus.$emit(name_event_bus, responce.data);
      //         }
      //       }
      //
      //       if (action === 'create'){
      //         this.afterCreate({data: responce.data, refForm: refForm, responce:responce})
      //       }else if (action === 'update'){
      //         this.afterUpdate({data: responce.data, refForm: refForm, responce:responce})
      //       }
      //
      //     }).catch((error) => {
      //       this.$message.error('error')
      //       console.log('error', error)
      //       this.afterUpdate({error:error})
      //     })
      //   }else{
      //     console.log('error')
      //   }
      // });

    }
  }
};
</script>

