<template>
  <div class="w-full">
    <a-input
        ref="userNameInput"
        placeholder="Поиск"
        class="ant-input-search"
        @input="onSearch"
        v-model="searchQuery"
    >
      <a-icon type="search" slot="prefix" style="color: rgba(0,0,0,.45)"/>
    </a-input>
  </div>

</template>

<script>
import SearchMixin from "@/crm_components/search/SearchMixin";

export default {
  name: "Search",
  mixins:[
      SearchMixin,
  ]
}
</script>

<style scoped>

</style>