<template>
  <div>
    <a-upload
        name="upload"
        :action="action"
        :headers="headers"
        :custom-request="customRequest"
        :before-upload="beforeUpload"
        @change="handleChange($event)"
        :default-file-list="defaultFileList"
    >
      <a-button> <a-icon type="upload" /> Select File </a-button>
    </a-upload>
  </div>
</template>

<script>
import axios from "@/configs/axios";

export default {
  name: "EditFiles",
  data() {
    return {
      data: [],
      fileList: [],
      form: this.$form.createForm(this, { name: 'coordinated' }),
      headers:{
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).access,
      },
    };
  },
  props:{
    defaultFileList:{
      type: Array,
      default: []
    }
  },
  computed:{
    action(){
      return process.env.VUE_APP_API_URL + '/common/file/'
    },
  },
  methods:{
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
    },
    async customRequest({ file, onSuccess, onError, onProgress }) {
      const formData = new FormData();
      formData.append('upload', file);

      try {
        const response = await axios.post(this.action, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            ...(file.headers || {})
          },
          onUploadProgress: (event) => {
            onProgress({ percent: (event.loaded / event.total) * 100 });
          }
        });
        onSuccess(response.data);
      } catch (error) {
        onError(error);
      }
    },
    handleChange(info) {
      console.log('info', info)
      let clone_file_list = info.fileList.map(el=>el.response.uid)
      this.data = clone_file_list
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        this.$message.success(`${info.file.name} file uploaded successfully`);


      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`);
      }
    },
  }

}
</script>

<style scoped>

</style>
