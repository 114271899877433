<template>
  <ValidationObserver v-slot="{ validate, invalid }" ref="refValidationObserver">
    <a-form :form="form" class="row">
      <a-form-item class="mb-2" :class="[item.col?`col-md-${String(item.col)} col-sm-12 pr-2`:'col-12']" v-for="(item, key) in form_items_clone" :key="key" style="margin-bottom: 0px;" v-if="hide_list.filter(ele=>ele===item.model).length===0">
        <div :class="[item.vertically !== true?'flex justify-between':'']" style="line-height: 30px;">
          <span v-if="showLabel(item)" class="w-1/2 mb-2">
            {{ $t(item.model)}}:
            <span v-if="isRequired(item)" class="text-red-500">*</span>
          </span>
            <ValidationProvider :rules="item.validate" v-slot="{ errors }" :name="item.model" class="w-full" >
              <div class="w-full">
                <template v-if="item.type === 'select'">
                    <ant-select
                        :mode="item.mode"
                        :clear_on_change="item.clear_on_change"
                        :client_data_params="item.client_data_params"
                        :use_last_request_params_event="item.use_last_request_params_event"
                        :repeat_request_event="item.repeat_request_event"
                        :clientData="clientData"
                        :disabled="item.read_only === true"
                        :use_router_replace="item.use_router_replace"
                        :filter_field="item.filter_field"
                        v-model="clientData[item.model]"
                        @input="selectHandleChange"
                        :placeholder="item.placeholder ? $t(item.placeholder) : $t(item.model)"
                        :end_point="item.end_point"
                        :select_list_component="item.select_list_component"
                        :showSearch="true"
                        :use_pagination="true"
                        :name="item.model"/>
                </template>
                <template v-else-if="item.type === 'tree_select'">
                    <TreeSelect
                        :mode="item.mode"
                        :clear_on_change="item.clear_on_change"
                        :client_data_params="item.client_data_params"
                        :use_last_request_params_event="item.use_last_request_params_event"
                        :repeat_request_event="item.repeat_request_event"
                        :clientData="clientData"
                        :disabled="item.read_only === true"
                        :use_router_replace="item.use_router_replace"
                        :filter_field="item.filter_field"
                        v-model="clientData[item.model]"
                        @input="selectHandleChange"
                        :placeholder="item.placeholder ? $t(item.placeholder) : $t(item.model)"
                        :end_point="item.end_point"
                        :select_list_component="item.select_list_component"
                        :showSearch="true"
                        :use_pagination="true"
                        :name="item.model"/>
                </template>
                <template v-else-if="item.type === 'button-add'">
                  <a-button
                      @click="click_add(item)"
                      type="primary"
                      class="default-button mr-1 base_text flex justify-center items-center"
                      size="small">
                    <a-icon type="plus" style="font-size: large; margin-right: 0px; width: 20px; height: 20px" />
                    <div class="ml-2">
                      {{ $t(item.model) }}
                    </div>
                  </a-button>
                  <div v-for="(ele, key_ele) in item.sub_items" :key="key_ele" class="mb-2">
                    <div class="flex justify-between ">
                      <div v-for="(ele_child, key_ele_child) in ele" :key="key_ele_child" style="width: 100%;" class="pr-2">
                        <ValidationProvider :rules="ele_child.validate" v-slot="{ errors }" :name="ele_child.model" class="w-full" >
                          <span v-if="showLabel(ele_child)" class="w-full">
                            {{ ele_child.label?ele_child.label:$t(ele_child.model)}}:
                            <span v-if="isRequired(ele_child)" class="text-red-500">*</span>
                          </span>
                          <template v-if="ele_child.type === 'select'">
                            <ant-select
                                :mode="ele_child.mode"
                                :clear_on_change="ele_child.clear_on_change"
                                :client_data_params="ele_child.client_data_params"
                                :use_last_request_params_event="ele_child.use_last_request_params_event"
                                :repeat_request_event="ele_child.repeat_request_event"
                                :clientData="clientData"
                                :disabled="ele_child.read_only === true"
                                :use_router_replace="ele_child.use_router_replace"
                                :filter_field="ele_child.filter_field"
                                v-model="clientData[ele_child.model]"
                                @input="selectHandleChange"
                                :placeholder="ele_child.placeholder ? $t(ele_child.placeholder) : $t(ele_child.model)"
                                :end_point="ele_child.end_point"
                                :select_list_component="ele_child.select_list_component"
                                :showSearch="true"
                                :use_pagination="true"
                                :name="ele_child.model"/>
                          </template>
                          <template v-else>
                            <a-input
                                :disabled="ele_child.read_only === true"
                                v-if="ele_child.mask"
                                v-mask="ele_child.mask"
                                :name="ele_child.model"
                                :placeholder="ele_child.placeholder ? $t(ele_child.placeholder) : $t(ele_child.model)"
                                v-model="clientData[ele_child.model]"/>
                            <a-input
                                v-else
                                :disabled="ele_child.read_only === true"
                                :name="ele_child.model"
                                :placeholder="ele_child.placeholder ? $t(ele_child.placeholder) : $t(ele_child.model)"
                                v-model="clientData[ele_child.model]"/>
                          </template>
                          <div v-if="errors.length>0">
                            <span v-if="errors[0].message === 'number_already_exists'">
                              <span class="flex flex-row flex-wrap justify-start" style="line-height: normal;">
                                <span class="text-red-500">
                                  {{ $t('number_already_exists') }}
                                </span>
                                <span class="a-link cursor-pointer" @click="number_already_exists(errors[0])">
                                  {{errors[0].data.profile.full_name}}
                                </span>
                              </span>
                            </span>
                                        <span v-else-if="errors[0].message === 'IIN_has_already_been'">
                              <span class="flex flex-row flex-wrap justify-start" style="line-height: normal;">
                                <span class="text-red-500">
                                  {{ $t('number_already_exists') }}
                                </span>
                                <span style="display: contents;">
                                  <span class="a-link cursor-pointer mb-2" @click="iin_already_exists(el)" v-for="(el, index) in errors[0].data" :key="index">
                                    {{el.profile.full_name}} - {{el.number}}
                                    <span v-if="index !== errors[0].data.length - 1">,</span>
                                  </span>
                                </span>
                              </span>
                            </span>
                                        <span v-else class="text-red-500">
                              {{ errors[0] }}
                            </span>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="flex items-end mb-1">
                        <a-button type="danger" shape="circle" icon="delete" @click="delete_sub_items(item, ele, key_ele)"/>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else-if="item.type === 'textarea'">
                    <a-textarea
                        :disabled="item.read_only === true"
                        v-model="clientData[item.model]"
                        :placeholder="item.placeholder ? $t(item.placeholder) : $t(item.model)"
                        :rows="5"/>
                </template>
                <template v-else-if="item.type === 'checkbox'">
                  <checkbox-form-select
                      :disabled="item.read_only === true"
                      :use_router_replace="item.use_router_replace"
                      :name="item.model"
                      :label="$t(item.model)"
                      v-model="clientData[item.model]"
                      @change="change_checkbox($event, item)"/>
  <!--                <a-checkbox v-model="clientData[item.model]" @change="change_checkbox($event, item)">-->
  <!--                  {{ $t(item.model) }}-->
  <!--                </a-checkbox>-->
                </template>
                <template v-else-if="item.type === 'date_time'">
                    <a-date-picker
                        :disabled="item.read_only === true"
                        v-model="clientData[item.model]"
                        show-time
                        :placeholder="item.placeholder ? $t(item.placeholder) : $t(item.model)"/>
                </template>
                <template v-else-if="item.type === 'upload'">
                    <a-upload
                        :disabled="item.read_only === true"
                        list-type="picture"
                        ref="refUpload"
                        name="upload"
                        :action="action"
                        :headers="headers"
                        :default-file-list="clientData[item.model]"
                        :custom-request="customRequest"
                        :before-upload="beforeUpload"
                        @change="handleChange($event, item)">
                      <a-button v-if="item.read_only !== true"> <a-icon type="upload" /> Select File </a-button>
                    </a-upload>
                </template>
                <template v-else-if="item.type === 'map'">
                  <div>
                    <div class="w-full">
                      <a-input v-model="clientData[`${item.model}_value`]" style="width: 100%;" disabled :name="item.model"/>
                    </div>
                    <div class="w-full">
                      <l-control class="searsh-form" style="width: 35vw;" name="map">
                        <a-form-item style="margin-bottom: 0px;">
                          <a-auto-complete
                              class="auto-complete"
                              :getPopupContainer="trigger => trigger.parentElement"
                              @change="handleAdressChange">
                            <template slot="dataSource">
                              <a-select-option
                                  v-for="(result, i) in autoCompleteResult"
                                  :key=String(i)
                                  @click="placeMarker(result, item)">
                                {{ result.label }}
                              </a-select-option>
                            </template>
                            <a-input-search placeholder="Поиск на карте"
                                            allowClear>
                            </a-input-search>
                          </a-auto-complete>
                        </a-form-item>
                      </l-control>
                      <l-map @click="onMapClick($event, item)" @dblclick="onMapClick($event, item)" :zoom="zoom" :center="center" style="height: 400px; width: 100%">
                        <l-tile-layer :url="url" />
                        <l-marker
                            v-for="(point, n) in markers"
                            :key="n"
                            :icon="customIcon"
                            :lat-lng="[point.lat, point.lng]">
                          <l-tooltip>{{ point.name }}</l-tooltip>
                        </l-marker>
                      </l-map>
                    </div>
                  </div>
                </template>
                <template v-else-if="item.type === 'date_start_end'">
                  <date-start-end
                      @change="updated_call"
                      v-model="clientData[item.model]"
                      :use_router_replace="item.use_router_replace"/>
                </template>
                <template v-else>
                    <a-input
                        :disabled="item.read_only === true"
                        v-if="item.mask"
                        v-mask="item.mask"
                        :name="item.model"
                        :placeholder="item.placeholder ? $t(item.placeholder) : $t(item.model)"
                        v-model="clientData[item.model]"/>
                    <a-input
                        v-else
                        :disabled="item.read_only === true"
                        :name="item.model"
                        :placeholder="item.placeholder ? $t(item.placeholder) : $t(item.model)"
                        v-model="clientData[item.model]"/>

                </template>
              </div>

              <div v-if="errors.length>0" style="line-height: normal;">
                <span v-if="errors[0].message === 'number_already_exists'">
                  <span class="flex flex-row flex-wrap justify-start" style="line-height: normal;">
                    <span class="text-red-500">
                      {{ $t('number_already_exists') }}
                    </span>
                    <span class="a-link cursor-pointer" @click="number_already_exists(errors[0])">
                      {{errors[0].data.profile.full_name}}
                    </span>
                  </span>
                </span>
                <span v-else-if="errors[0].message === 'IIN_has_already_been'">
                  <span class="flex flex-row flex-wrap justify-start" style="line-height: normal;">
                    <span class="text-red-500">
                      {{ $t('number_already_exists') }}
                    </span>
                    <span style="display: contents;">
                      <span class="a-link cursor-pointer mb-2" @click="iin_already_exists(el)" v-for="(el, index) in errors[0].data" :key="index">
                        {{el.profile.full_name}} - {{el.number}}
                        <span v-if="index !== errors[0].data.length - 1">,</span>
                      </span>
                    </span>
                  </span>
                </span>
                <span v-else class="text-red-500">
                  {{ errors[0] }}
                </span>
              </div>


            </ValidationProvider>
        </div>
      </a-form-item>
    </a-form>
  </ValidationObserver>
</template>

<script>
import { ValidationProvider, ValidationObserver } from '@/configs/vee-validate';
import axios from "@/configs/axios";
import AntSelect from "@/components/AntSelect/AntSelect";
import TreeSelect from "@/components/AntSelect/TreeSelect";
import { mask } from 'vue-the-mask'
import EditFiles from "@/crm_components/files/EditFiles";
import GoogleMap from "@/crm_components/GoogleMap/GoogleMap";
import MapMixin from "@/crm_components/GoogleMap/MapMixin";
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import DateStartEnd from "@/components/Forms/FormAddClient/DateStartEnd";
import CheckboxFormSelect from "@/components/Forms/FormAddClient/CompsFormSelect/CheckboxFormSelect";
import {EventBus} from "@/configs/eventBus";
import { OpenStreetMapProvider } from 'leaflet-geosearch'
let timer

export default {
  name: "FormSelect",
  components:{
    CheckboxFormSelect,
    DateStartEnd,
    AntSelect,
    ValidationProvider,
    ValidationObserver,
    TreeSelect,
    EditFiles,
    GoogleMap,
    LMap,
    LTileLayer,
    LMarker,
  },
  mixins:[
      MapMixin
  ],
  directives: {
    mask
  },
  data() {
    return {
      clientData: {},
      initial_form: {},
      fileList: [],
      form: this.$form.createForm(this, { name: 'coordinated' }),
      headers:{
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).access,
      },
      hide_list:[],
      customIcon:L.icon({
        iconUrl: 'https://cdn-icons-png.flaticon.com/512/443/443025.png',
        iconSize: [30, 30],
      }),
      form_items_clone: this.formItems,
      control_model_name:{},
      zoom: 13,
      marker: [51.505, -0.09],
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      autoCompleteResult:[],
      provider: new OpenStreetMapProvider({
        params: {
          'accept-language': 'ru',
          countrycodes: ['ru', 'kz'],
          addressdetails: 0,
          limit: 7,
        },
      }),
    };
  },
  props:{
    formItems:{
      type: Array,
      default: null
    },
    cols:{
      type: Number,
      default: 2
    },
    show_label:{
      type: Boolean,
      default: false
    },
    updated_event_bus:{
      type: String,
      default: ''
    }
  },
  computed:{
    action(){
      let base_url = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}/api_rm52/v37`;
      if (window.location.hostname === 'localhost') {
        base_url = 'http://127.0.0.1:8000/api_rm52/v37';
      }
      return base_url + '/common/file/';
    },

  },
  methods: {
    updateMarker(latLng) {
      console.log('latLng', latLng)
      // this.marker = latLng;
      // this.clientData['map_location'] = latLng;
    },
    async handleAdressChange(value) {
      console.log('value', value)
      if(value === '') {
        this.autoCompleteResult = []
        this.searchVisible = false
        this.deliveryPoint = {
          lat: null,
          lon: null,
          address: '',
          name: '',
        }
        this.$emit('markedPoint', this.deliveryPoint)
      }
      clearTimeout(timer)
      if(value.trim().length >= 3) {
        timer = setTimeout(() => {
          this.searchLoading = true
          try {
            this.provider.search({ query: value })
                .then((result)=> {
                  this.autoCompleteResult = result
                })
          } catch(e) {
            console.log(e)
          } finally {
            this.searchLoading = false
          }
        }, 700)
      }
    },
    click_add(item){
      let clone_form_items_clone = []
      this.form_items_clone.forEach((el)=>{
        if (el.model === item.model){
          if (item.model in this.control_model_name) {
            this.control_model_name[item.model] += 1
          } else {
            this.control_model_name[item.model] = 1
          }
          console.log('this.control_model_name', this.control_model_name)

          let sub_items_clone = []
          el.add_items.forEach((ele)=>{
            sub_items_clone.push({
              ...ele,
              model: `${ele.model}_${this.control_model_name[item.model]}`
            })
          })
          el.sub_items.push(sub_items_clone)
        }

        clone_form_items_clone.push(el)

      })
      console.log('clone_form_items_clone', clone_form_items_clone)
      this.form_items_clone = clone_form_items_clone
    },
    delete_sub_items(item, ele, key_ele){
      let clone_form_items_clone = []
      this.form_items_clone.forEach((el)=>{
        if (el.model === item.model){
          el.sub_items = el.sub_items.filter((ele_child)=>ele_child!==ele)
        }
        clone_form_items_clone.push(el)
      })
      console.log(item, ele, key_ele)
      let clone = {...this.clientData}
      ele.forEach((el)=>{
        delete clone[el.model]
      })
      this.clientData = clone
      this.form_items_clone = clone_form_items_clone

    },
    throw_off(){
      this.clientData = {}
    },
    clean_all_key(){
      let clone = {}
      Object.keys(this.clientData).forEach(key => {
        clone[key] = '';
      });
      this.clientData = clone
    },

    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
    },
    async customRequest({ file, onSuccess, onError, onProgress }) {
      const formData = new FormData();
      formData.append('upload', file);

      try {
        const response = await axios.post(this.action, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            ...(file.headers || {})
          },
          onUploadProgress: (event) => {
            onProgress({ percent: (event.loaded / event.total) * 100 });
          }
        });
        onSuccess(response.data);
      } catch (error) {
        onError(error);
      }
    },
    handleChange(info, item) {
      console.log('info', info)
      console.log('item', item)
      let clone_file_list = info.fileList.map((el)=>{
        console.log(el)
        if (el.response){
          return el.response.uid
        }
        return el.uid
      })
      this.clientData[item.model] = clone_file_list
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        this.$message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`);
      }
    },
    selectHandleChange(val){
      this.clientData[val.name] = val.select
      this.updated_call()
    },
    showLabel(item) {
      return item.show_label === undefined || item.show_label === true;
    },
    isRequired(item) {
      return item.validate && item.validate.includes('required');
    },
    number_already_exists(errors){
      this.$emit('number_already_exists', errors);
      console.log('errors', errors);
    },
    iin_already_exists(errors){
      this.$emit('iin_already_exists', errors);
      console.log('errors', errors);
    },
    async default_change(value, item){
      console.log('name', value)
      console.log('item', item)
      this.clientData[item.model] = await value
      console.log('this.clientData', this.clientData)
    },
    async change_map(name, address){
      console.log('name', name)
      console.log('address', address)
      console.log('this.clientData', this.clientData)
      let clone = {...this.clientData}
      clone[name] = await address
      clone[`${name}_value`] = await address.full_address
      this.clientData = clone
    },
    change_checkbox(e, item){
      console.log('item', item)
      console.log('e', e)

      let clone = {...this.clientData}
      clone[item.model] = e.target.checked;
      this.clientData = clone
      if (item.hide){
        if (item.hide.length>0){
          if (e.target.checked === item.in_case){
            item.hide.forEach((ele)=>{
              this.hide_list.push(ele)
            })
          }else{
            item.hide.forEach((ele)=>{
              this.hide_list = this.hide_list.filter(item=>item!==ele)
            })
          }
        }
      }
      this.updated_call()

    },
    updated_call(){
      console.log('updated_call')
      EventBus.$emit(this.updated_event_bus);
    }
  }
}
</script>

<style lang="scss">
.form-select{
  .ant-form-item-control{
    line-height: 30px;
  }
}
</style>
