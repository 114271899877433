<script>
import formSaveMixin from "@/crm_components/form_save/FormSaveMixin";

export default {
  name: "DeleteMixin",
  mixins: [
    formSaveMixin
  ],
  methods: {
    delete_method(name_event_bus, end_point, data){
      this.$confirm({
        title: this.$t('are_you_sure_delete'),
        okText: this.$t('yes'),
        okType: 'danger',
        cancelText: this.$t('no'),
        onOk: () => {
          this.handleSubmit(null, name_event_bus+"_delete", end_point, 'delete', data)
          this.$emit('close')
        },
        onCancel() {
          console.log('Cancel');
        },
      });

    }
  }
}
</script>

<style scoped>

</style>