<template>
  <div>
    <div class="form-card w-full flex mb-1">
      <div class="flex w-1/3">
        <div class="w-1/2 p-2">
          <a-icon :type="icon_type" style="width: 100%; font-size: 160px;"/>
        </div>
        <div class="w-1/2 p-2">
          <slot name="form-card-text"></slot>
        </div>
      </div>
      <div class="flex w-2/3 p-4">
        <FormSelect :formItems="formItems" ref="refForm"/>
      </div>
    </div>
    <div class="flex justify-end mb-1">
      <a-button type="primary" class="default-button mr-1" size="small" @click="handleSubmit">
        Сохранить
      </a-button>
      <a-button type="primary" class="default-button" size="small">
        Отмена
      </a-button>
    </div>
  </div>
</template>

<script>
import AntSelect from "@/components/AntSelect/AntSelect";
import axios from "@/configs/axios";
import FormSelect from "@/components/Forms/FormAddClient/FormSelect";
import {EventBus} from "@/configs/eventBus";

export default {
  name: "FormAddClientComponent",
  components:{
    FormSelect,
    AntSelect,
  },
  props:{
    end_point: {
      type: String,
      default: ''
    },
    icon_type: {
      type: String,
      default: ''
    },
    formItems:{
      type: Array,
      default: []
    },
    use_emit_save:{
      type: Boolean,
      default: false
    },
    name_event_bus: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      // clientData: {},
      // form: this.$form.createForm(this, { name: 'coordinated' }),
    };
  },
  methods: {
    function_to_get_list(val){
      return axios.post(`${this.end_point}`, val.data,{params:val.params},  )
    },
    handleSubmit(){
      this.$refs.refForm.form.validateFields((err, values) => {
        if (!err) {
          this.function_to_get_list({
            data:{...this.$refs.refForm.clientData},
            params:{...this.$route.query},
          }).then((responce) => {
            this.$message.success(this.$t('success'));
            EventBus.$emit(this.name_event_bus, responce.data);
            this.$refs.refForm.clean_all_key()
            // let clone = {}
            // Object.keys(this.$refs.refForm.clientData).forEach(key => {
            //   console.log('this.$refs.refForm.clientData[key]', this.$refs.refForm.clientData[key])
            //   clone[key] = '';
            // });
            // this.$refs.refForm.clientData = clone
            console.log('responce', responce)
          }).catch((error) => {
            this.$message.error('error')
            console.log('error', error)
          })
        } else {
          console.log('error')
        }
      });
    }
  }
};
</script>
<style lang="scss">
@import "src/scss/base/_variables.scss";

.form-card{
  background: $main-color-rm;
  color: $color-white;
  border-radius: $border-radius-base;
  padding: 20px;
}

//.btn-save {
//  margin-top: 10px;
//  padding: 8px 16px;
//  background-color: $main-color;
//  color: $color-white;
//  border: none;
//  border-radius: $border-radius-base;
//  cursor: pointer;
//}
//
//.btn-save:hover {
//  background-color: darken($main-color, 10%);
//}
</style>
