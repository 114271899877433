<template>
<div>
  {{item.profile.full_name}} - {{item.number}}
</div>
</template>

<script>
export default {
  name: "PhoneNumber",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
}
</script>

<style scoped>

</style>
