<template>
  <a-dropdown>
    <a-menu slot="overlay">
      <a-menu-item @click="updateLocale('en')">
        <span>
          <i class="flag-icon flag-icon-gb" style="margin-right: 8px;"></i> English
        </span>
      </a-menu-item>
      <a-menu-item @click="updateLocale('ru')">
        <span>
          <i class="flag-icon flag-icon-ru" style="margin-right: 8px;"></i> Русский
        </span>
      </a-menu-item>
      <a-menu-item @click="updateLocale('kz')">
        <span>
          <i class="flag-icon flag-icon-kz" style="margin-right: 8px;"></i> Қазақша
        </span>
      </a-menu-item>
    </a-menu>
    <a-button style="margin-left: 8px">
      <i :class="currentFlag" style="margin-right: 8px;"></i>
      <a-icon type="down" />
    </a-button>
  </a-dropdown>
</template>

<script>
export default {
  name: 'LanguageSwitcher',
  data() {
    return {
    };
  },
  computed: {
    currentLang() {
      return this.$route.params.lang;
    },
    currentFlag() {
      switch (this.currentLang) {
        case 'en':
          return 'flag-icon flag-icon-gb';
        case 'ru':
          return 'flag-icon flag-icon-ru';
        case 'kz':
          return 'flag-icon flag-icon-kz';
        default:
          return 'flag-icon flag-icon-gb';
      }
    }
  },
  methods: {
    updateLocale(locale) {
      this.$i18n.locale = locale;
      // this.$moment.locale(locale);
      const query = this.$route.query;
      try {
        this.$router.replace({
          params: { lang: locale },
          query: query,
        });
      }catch (e) {

      }

    },
  }
};
</script>

<style scoped>
.flag-icon {
  width: 1.5em;
  height: 1em;
}
</style>
