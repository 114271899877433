/*
=========================================================
Muse - Vue Ant Design Dashboard - v1.0.0
=========================================================

Product Page: https://www.creative-tim.com/product/vue-ant-design-dashboard
Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by Creative Tim

=========================================================
The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Vue from 'vue'
import Antd from 'ant-design-vue';
import App from './App.vue'
import DefaultLayout from './layouts/Default.vue'
import DashboardLayout from './layouts/Dashboard.vue'
import DashboardRTLLayout from './layouts/DashboardRTL.vue'
import router from './router'
import store from "@/store/store";
import './assets/tailwind.css'
import i18n from './configs/i18n';
// import './plugins/click-away'

import './scss/app.scss';

import 'flag-icon-css/css/flag-icons.min.css';
Vue.use(Antd);

Vue.config.productionTip = false

// Adding template layouts to the vue components.
Vue.component("layout-default", DefaultLayout);
Vue.component("layout-dashboard", DashboardLayout);
Vue.component("layout-dashboard-rtl", DashboardRTLLayout);

import { ValidationProvider, extend } from '@/configs/vee-validate';
Vue.component('ValidationProvider', ValidationProvider);

// import VeeValidate from '@/configs/vee-validate';
// Vue.use(VeeValidate, {
//   i18n,
//   dictionary: require('@/configs/VeeValidate').default,
//   fieldsBagName: 'veeFields' // Поставил, так как дефолтное название fields конфликтует с vue-excel-export
// });
import './configs/validation-rules';

import * as VueGoogleMaps from 'vue2-google-maps';

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBQjz5saIO0BSy8SAH3G7Cxk1dYf2hdWdw', // Ваш API ключ Google Maps
    libraries: 'places', // Необходимые библиотеки Google Maps
  },
});
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
