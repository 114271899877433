<template>
  <div>
    <a-layout-sider
        class="sider-primary ant-layout-sider-primary ant-layout-sider-white"
        breakpoint="lg"
        collapsed-width="0"
        width="220px"
        :trigger="null"
        theme="light">
      <!-- Sidebar Navigation Menu -->
      <a-menu theme="light" mode="inline">
        <a-menu-item v-for="(item, index) in routes_clone_v" :key="index" >
          <router-link :to="localizedRoute(`/home/${item.path}`)" style="display: flex;align-items: center; padding: 9px 15px;">
              <div style="display: flex; justify-content: start; align-items: center;">
                <a-icon :type="item.icon" style="width: 25px; height: 25px; font-size: 23px; margin-right: 2px;"/>
              </div>
              <span class="label" >{{$t(item.name)}}</span>
<!--            <div>-->
<!--              <a-icon type="plus" style="font-size: large; margin-right: 0px; width: 20px; height: 20px" />-->
<!--            </div>-->
          </router-link>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
  </div>
</template>
<script>
import routes from "@/router/home";
import {mapGetters} from "vuex";
export default {
  name: "DashboardSidebar.vue",
  components:{
  },
  data(){
    return{
      routes_clone:routes
    }
  },
  computed: {
    ...mapGetters({
      GET_PROFILE_INFO:'user/GET_PROFILE_INFO',
    }),
    currentLang() {
      return this.$route.params.lang;
    },
    routes_clone_v(){
      console.log('routes_clone', this.routes_clone)
      console.log('GET_PROFILE_INFO', this.GET_PROFILE_INFO)
      let _routes = []
      if (this.GET_PROFILE_INFO){
        let pages_front = this.GET_PROFILE_INFO.pages_front
        console.log('pages_front', pages_front)
        this.routes_clone.forEach(async (item)=>{
          if(pages_front.find(ele=>ele.name===item.name)){
            _routes.push(item)
          }
        })
      }
      return _routes
    }
  },
  methods: {
    localizedRoute(path) {
      return `/${this.currentLang}${path}`;
    }
  },
}
</script>
