<script>
import ProfileSelectView from "@/components/AntSelect/ProfileSelectView";
import PhoneNumber from "@/components/AntSelect/ItemViews/PhoneNumber";

export default {
  name: "RequestFilterMixin",
  data(){
    return{
      filters:[
        {
          model:'health_threat',
          type:'checkbox',
          show_label: false,
          use_router_replace:true
        },
        {
          model:'filter_date_start_end',
          type:'date_start_end',
          use_router_replace:true
        },
        {
          model: 'request_type',
          type:'select',
          end_point:'organizations/request_types/',
          use_router_replace:true
        },
        {
          model: 'client',
          type:'select',
          end_point:'organizations/profiles_client/',
          select_list_component:ProfileSelectView,
          use_router_replace:true
        },
        {
          model: 'level_request',
          type:'select',
          end_point:'organizations/levels/',
          use_router_replace:true
        },
        {
          model: 'source_request',
          type:'select',
          end_point:'organizations/sources/',
          use_router_replace:true
        },
        {
          model: 'organization_request',
          type:'select',
          end_point:'organizations/organizations/',
          use_router_replace:true
        },
        {
          model: 'responsible_manager',
          type:'select',
          end_point:'organizations/performer/',
          select_list_component:ProfileSelectView,
          use_router_replace:true
        },
        {
          model: 'operator',
          type:'select',
          end_point:'organizations/operator/',
          select_list_component:ProfileSelectView,
          use_router_replace:true
        },
        {
          model: 'region_fk',
          type:'select',
          end_point:'organizations/regions/',
          use_router_replace:true
        },
        {
          model: 'status_request',
          type:'select',
          end_point:'organizations/statuses/',
          use_router_replace:true
        },

      ]
    }
  }
}
</script>

<style scoped>

</style>
