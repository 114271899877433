import axios from '@/configs/axios';

const state = () => ({
    config: {
        map: {
            tileUrl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', // Replace with your actual tile URL if different
            tileToken: 'your-mapbox-access-token', // If required
            tileLang: 'ru' // Language for map tiles
        }
    },
    pages_front:[]
});

const getters = {
    GET_PAGES_FRONT: s => s.pages_front,
};

const mutations = {
    SET_PAGES_FRONT(state, value){
        state.pages_front = value
    },

};

const actions = {
    async PAGES_FRONT({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.get('organizations/pages_front/', {params:payload})
                .then(response => {
                    commit('SET_PAGES_FRONT', response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
