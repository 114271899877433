<script>
let searchId;
export default {
  name: "SearchMixin",
  props:{
    query_name:{
      type:String,
      default:'search',
    },
  },
  computed:{
    searchQuery: {
      get () {
        return this.$route.query[this.query_name]
      },
      set (val) {
        // this.$store.commit('student_list/SET_SEARCH_QUERY', val)
      }
    },
  },
  methods:{
    async onSearch(event) {
      console.log('ladslldaslads')
      this.searchQuery = event.target.value
      let query = Object.assign({}, this.$route.query)
      // Подменяем query в роуте на введенные в строку для поиска данные, проверяем что введенные данные не пустые
      if(event.target.value.length > 0) {
        query[this.query_name] = event.target.value
      } else if(Object.keys(this.$route.query).includes(this.query_name)) {
        delete query[this.query_name]
      }
      try {
        await this.$router.push({query})
      }catch (e) {

      }
      // Не успевал поставить в роут, сделал await
      // Очищаем таймер
      clearTimeout(searchId);

      // Создаем таймер при завершении которого отправляем запрос к серверу
      searchId = setTimeout(async () => {
        await this.$emit('updated_data')
      }, 500);
    },
  }
}
</script>

<style scoped>

</style>