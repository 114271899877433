<template>
<div>
  {{ordinal_number}}
</div>
</template>

<script>
export default {
  name: "AgGridTableNumber",
  computed:{
    item(){
      return this.params.data
    },
    ordinal_number(){
      let currentPage = Number(this.$route.query[this.params.colDef.query_page_name])
      let rowsPerPage = 20
      let currentRowIndex = this.params.node.rowIndex
      return (currentPage - 1) * rowsPerPage + currentRowIndex + 1;
    }
  }
}
</script>

<style scoped>

</style>
