<template>
  <div>
    <div ref="tabularTable"></div>
  </div>
</template>
<script>
import Tabulator from './tabulator-master/dist/js/tabulator.js'
import {EventBus} from "@/configs/eventBus";

export default {
  name: "Tabular",
  props:{
    columnDefs:{
      type:Array,
      default: null
    },
    rowData:{
      type:Array,
      default: null
    }
  },
  async mounted() {
    await this.$nextTick();
  },
  watch:{
    rowData(newData) {
      this.updateTableData(newData);
    },
    columnDefs(){
      this.updateTableData(this.rowData);
    }
  },
  methods:{
    method_mounted(){
      console.log('this.columnDefs', this.columnDefs)
      this.tabulator = new Tabulator(this.$refs.tabularTable, {
        data: this.rowData, //link data to table
        reactiveData:true, //enable data reactivity
        columns: this.columnDefs, //define table columns
        dataTree:true,
        dataTreeStartExpanded:false,
      });
      this.tabulator.on("rowClick", this.click_row);
      this.tabulator.on("dataTreeRowExpanded", this.click_data_tree_row_expanded);
      console.log('this.$refs.tabularTable', this.tabulator)
    },
    updateTableData(newData) {
      if (this.tabulator) {
        this.tabulator.setData(newData); // update data without recreating the table
      }else{
        this.method_mounted()
      }
    },
    download(){
      this.tabulator.download("xlsx", "data.xlsx", {
        documentProcessing:function(workbook){
          //workbook - sheetJS workbook object

          //set some properties on the workbook file
          workbook.Props = {
            Title: "SheetJS Tutorial",
            Subject: "Test",
            CreatedDate: new Date(2017,12,19)
          };

          return workbook;
        }
      });
    },
    click_row(e, row){
      console.log('e', e)
      console.log('row', row)
      // if (e.srcElement.classList.value.includes('a-link')){
      this.$emit('click', {data: row.getData()})
      // }
    },
    wait(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async click_data_tree_row_expanded(row, level){
      this.$emit('click_data_tree_row_expanded', row, level)
    }
  }
}
</script>

<style scoped lang="scss">

</style>