import { render, staticRenderFns } from "./ClientShowDrawer.vue?vue&type=template&id=cda618e4&scoped=true"
import script from "./ClientShowDrawer.vue?vue&type=script&lang=js"
export * from "./ClientShowDrawer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cda618e4",
  null
  
)

export default component.exports