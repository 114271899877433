import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from '../locales/en.json';
import ru from '../locales/ru.json';
import kz from '../locales/kz.json';

Vue.use(VueI18n);

const messages = {
    en,
    ru,
    kz
};

const i18n = new VueI18n({
    locale: 'ru', // Установите язык по умолчанию
    fallbackLocale: 'ru',
    messages
});

export default i18n;
