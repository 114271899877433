<template>
  <div>
    <div class="sidebar_item" v-if="data.author">
      <div class="title">
        {{ $t('operator') }}
      </div>
      <div>
        {{ data.author.full_name }}
      </div>
    </div>
    <div class="sidebar_item">
      <div class="title">
        {{ $t('data_created') }}
      </div>
      <div>
        {{ data.created }}
      </div>
    </div>
    <div class="sidebar_item" v-if="data.client">
      <div class="title">
        {{ $t('client') }}
      </div>
      <div class="a-link cursor-pointer" @click="go_client">
        {{ data.client.profile.full_name }}
      </div>
    </div>
    <div class="sidebar_item" v-if="data.level_request">
      <div class="title">
        {{ $t('level_request') }}
      </div>
      <div>
        {{ data.level_request.name }}
      </div>
    </div>
    <div class="sidebar_item" v-if="data.request_type">
      <div class="title">
        {{ $t('request_type') }}
      </div>
      <div>
        {{ data.request_type.name }}
      </div>
    </div>
    <div class="sidebar_item" v-if="data.days_until_due !== null">
      <div class="title">
        {{ $t('days_until_due') }}
      </div>
      <div :class="{'red-text': Number(data.days_until_due) <= 0}">
        {{ data.days_until_due }}
      </div>
    </div>
    <div class="sidebar_item" v-if="data.status_request">
      <div class="title">
        {{ $t('status_request') }}
      </div>
      <div>
        {{ data.status_request.name }}
      </div>
    </div>
    <div class="sidebar_item" v-if="data.source_request">
      <div class="title">
        {{ $t('source_request') }}
      </div>
      <div>
        {{ data.source_request.name }}
      </div>
    </div>
    <div class="sidebar_item" v-if="data.result_request">
      <div class="title">
        {{ $t('result_request') }}
      </div>
      <div>
        {{ data.result_request.name }}
      </div>
    </div>
    <div class="sidebar_item" >
      <div class="title">
        {{ $t('organization_request') }}
      </div>
      <div v-if="data.organization_request">
        {{ data.organization_request.name }}
      </div>
      <div v-else>
        {{ $t('not_specified') }}
      </div>
    </div>
    <div class="sidebar_item no-border" >
      <div class="title">
        {{ $t('executor') }}
      </div>
      <div v-if="data.responsible_manager">
        {{ data.responsible_manager.full_name }}
      </div>
      <div v-else>
        {{ $t('not_specified') }}
      </div>
    </div>
  </div>
</template>

<script>
import FormattedDateMixin from "@/crm_components/mixins/FormattedDateMixin";

export default {
  name: "RequestRightInfo",
  props:{
    data:{
      type:Object,
      default:{}
    },
  },
  mixins:[
    FormattedDateMixin
  ],
  methods:{
    go_client(){
      this.$emit('go_client', this.data.client.profile)
    }
  }

}
</script>

<style scoped lang="scss">
@import "src/scss/base/_variables.scss";

.title {
  font-weight: 600;
  margin-bottom: 0.25rem;
  font-size: 15px;
  line-height: 1.25rem;
}

.sidebar_item {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #f1f1f1;
}

.no-border {
  border-bottom: none;
}
.red-text {
  color: red;
}
</style>
