<template>
  <div class="flex justify-between">
      <div class="title">
        {{ data.title }}
      </div>
      <div>
        {{ data.value }}
      </div>
  </div>
</template>

<script>
export default {
  name: "DescriptionInfoItem",
  props:{
    data:{
      type: Object,
      default: {}
    },
  },
}
</script>

<style scoped>

</style>